import { render, staticRenderFns } from "./batch_schedules.vue?vue&type=template&id=a89bfe8a&scoped=true"
import script from "./batch_schedules.vue?vue&type=script&lang=js"
export * from "./batch_schedules.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a89bfe8a",
  null
  
)

export default component.exports