<template>
    <v-expand-x-transition>
        <v-navigation-drawer
            fixed
            right
            color="info"
            class="pr-2 pb-6 inde_flex_container"
            style="height:100%;width:100%;"
            :permanent="value"
            :value="value"
            v-on:input="$emit('input', $event)"
        >
            <div class="inde_flex_header">
                <v-row>
                    <v-col align="right">
                        <v-app-bar-nav-icon @click="$emit('input', false)" class="mt-3"/>
                    </v-col>
                </v-row>
            </div>
            <v-list class="inde_flex_content">
                <v-list-item
                    v-for="link in links"
                    :key="link.name"
                    class="white--text"
                >
                    <v-list-item-content class="white--text">
                        <v-list-item-title>
                            <v-btn
                                text
                                @click="$emit('input', false)"
                                :to="link.url"
                                class="text-h6 white--text font-weight-bold pa-0 text-none mr-2"
                            >
                                {{ link.name }}
                            </v-btn>
                        </v-list-item-title>
                    </v-list-item-content>

                </v-list-item>

            </v-list>
            <div class="inde_flex_footer">

                <v-list>
                    <v-list-item v-if="current_user != null">
                        <v-list-item-content>
                            <v-list-item-title class="text-h6 white--text font-weight-bold pa-0 text-none mr-2">
                                {{ current_user.full_name }}
                            </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-avatar>
                            <v-avatar left size="40">
                                <img
                                    v-if="current_user.picture"
                                    alt="User Picture"
                                    :src="current_user.picture"
                                >
                            </v-avatar>
                        </v-list-item-avatar>


                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-btn text class="white--text" @click="logout_internal">
                                <v-icon>mdi-logout</v-icon>
                                {{ $t('common.logout') }}
                            </v-btn>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        v-for="(language, index) in locales"
                        :key="'language_' + index"
                        @click="change_locale({locale:index, i18n: $i18n})"
                    >
                        <v-list-item-avatar
                            class="pb-1"
                            tile
                        >
                            <CountryFlag
                                :country="language"
                                size="normal"
                            />

                        </v-list-item-avatar>
                        <v-list-item-title>{{ $t(`common.${index}`) }}</v-list-item-title>
                        <v-list-item-avatar
                            tile
                        >
                            <v-icon v-if="index === $i18n.locale">
                                mdi-check-circle
                            </v-icon>
                        </v-list-item-avatar>
                    </v-list-item>
                </v-list>
            </div>
        </v-navigation-drawer>
    </v-expand-x-transition>

</template>
<script>
import {layout_mixin} from "@/shared/mixins/layout";
import {mapActions, mapGetters} from "vuex";
import CountryFlag from 'vue-country-flag'

export default {
    name: "NavigationDrawer",
    mixins: [layout_mixin],
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    components: {CountryFlag},
    data() {
        return {}
    },
    computed: {
        ...mapGetters({links: "variables/links"}),
    },

    methods: {
        ...mapActions({
            logout: "auth/logout",
            change_locale: "auth/change_locale"
        }),
        logout_internal() {
            this.logout()
        }

    },

}
</script>

<style scoped>


</style>
  
  
  