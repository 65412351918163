<template>
    <div :style='{"max-width":header_item?.width ? header_item.width : "20wv"}'>
        <v-checkbox v-if="display_type ==='boolean'" v-model="display_value" disabled dense>
        </v-checkbox>
        <v-tooltip
            left
            v-else-if="display_value || display_value === 0"
        >
            <template v-slot:activator="{ on, attrs }">
                <p
                    :style="{'max-width':'100%', 'max-height': '20px'}"
                    class="cut-text"
                    v-bind="attrs"
                    v-on="on"
                >
                    {{ display_value }}
                </p>
            </template>
            {{ display_value }}
        </v-tooltip>
        <p v-else>
            -
        </p>
    </div>
</template>
<script>
import {dialog_mixin} from "@/shared/mixins/dialog_mixin";
import {mapActions, mapGetters} from "vuex";
import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: "IndeValue",
    mixins: [dialog_mixin, layout_mixin],
    props: {
        item: {},
        header_item: {type: Object},
        resource: {type: String}
    },
    data() {
        return {
            display_value: null,
            display_type: null
        }
    },
    computed: {
        ...mapGetters({
            domain_settings: "dialog/domain_settings",
        })
    },
    methods: {
        async update_data() {
            let {value, type} = await this.format_data(this.item, this.header_item, this.domain_settings, this.resource)
            this.display_value = value
            this.display_type = type
        }
    },
    watch: {
        "item.value": async function () {
            await this.update_data()
        }
    },
    async created() {
        await this.update_data()
    }
}
</script>
<style>
.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>