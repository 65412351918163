<template>
    <div>
        <v-data-table
            :loading="loading"
            :headers="internal_headers"
            :items="internal_items"
            :resource="resource"
            :expanded.sync="expanded"
            class="elevation-1"
            item-key="_id"
            :server-items-length="total_results"
            :options.sync="options"
            :show-expand="expandable"
            :footer-props='{"items-per-page-options": [20, 40, 100] }'
            @click:row.capture="click_row_internal($event)"
            multi-sort
        >
            <template v-slot:top>
                <slot name="top">
                    <v-toolbar flat v-if="!hide_header">
                        <v-toolbar-title class="hidden-sm-and-down">{{ translate(resource) }}</v-toolbar-title>

                        <v-divider
                            class="mx-4"
                            inset
                            vertical
                        />
                        <slot name="search">
                        </slot>
                        <v-spacer/>
                        <v-btn icon v-on:click="get_data">
                            <v-icon>
                                mdi-refresh
                            </v-icon>
                        </v-btn>
                        <slot name="create_new">
                            <CreateNew
                                v-if="resource"
                                :resource="resource"
                                :hide_create_new="hide_create_new"
                                :default_data="default_data"
                                :locked_data="locked_data"
                                :dialog_settings="dialog_settings"
                            />
                        </slot>
                    </v-toolbar>
                </slot>

            </template>
            <template
                v-for="header in internal_headers.filter(x => !custom_headers.includes(x.value) )"
                v-slot:[`item.${header.value}`]="{ item }">
                <IndeValue
                    :item="item"
                    :header_item="header"
                    :resource="resource"
                />
            </template>
            <template
                v-for="custom_header in custom_headers"
                #[`item.${custom_header}`]="{ item }"
            >
                <slot
                    :name="`item.${custom_header}`"
                    :item="item"
                />
            </template>
            <template #item.actions_view="{ item }">
                <v-icon
                    class="mb-2"
                    small
                    @click.stop="click_row_internal(item)"
                >
                    mdi-card-search
                </v-icon>
            </template>
            <template #item.actions_edit="{ item }">
                <v-icon
                    class="mb-2"
                    small
                    @click.stop="edit_item(item)"
                >
                    mdi-pencil
                </v-icon>
            </template>
            <template #item.actions_delete="{ item }">
                <v-icon
                    class="mb-2"
                    small
                    @click.stop="change_delete_item({item:item, resource:resource})"
                >
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <slot
                    name="expanded_item"
                    :item="item"
                    :headers="headers">
                </slot>
            </template>
        </v-data-table>
        <p v-if="!status.ok">
            Error {{ status.message }}
        </p>

    </div>
</template>
<script>
import CreateNew from "@/shared/components/dialogs/create_new_btn";
import {mapActions, mapGetters} from "vuex";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {dialog_mixin} from "@/shared/mixins/dialog_mixin";
import {dict_mixin} from "@/shared/mixins/dict_mixin";
import IndeValue from "@/shared/components/view/inde_value.vue";
import {layout_mixin} from "@/shared/mixins/layout";
import Loading from "@/shared/components/loading.vue";

export default {
    name: "inde-table-view",
    components: {Loading, IndeValue, CreateNew},
    mixins: [api_mixin, dialog_mixin, dict_mixin, layout_mixin],
    props: {
        resource: {
            type: String,
            required: true
        },
        lookup: {
            type: Object,
            default: () => {
            }
        },
        default_data: {
            type: Object,
            default: () => {
            }
        },
        locked_data: {
            type: Object,
            default: () => {
            }
        },
        hide_create_new: {
            type: Boolean,
            default: false
        },
        init_sort_by: {
            type: Array,
            default: () => []
        },
        init_sort_desc: {
            type: Array,
            default: () => []
        },
        actions: {
            type: Boolean,
            default: false
        },
        actions2: {
            type: Boolean,
            default: false
        },
        expandable: {
            type: Boolean,
            default: false
        },
        hide_actions: {
            type: Boolean,
            default: false
        },
        hide_edit: {
            type: Boolean,
            default: false
        },
        hide_view: {
            type: Boolean,
            default: false
        },
        dialog_settings: {
            type: Object,
            default() {
                return {}
            }
        },
        auth: {
            type: Boolean,
            default: true
        },
        hide_header: {
            type: Boolean,
            default: false
        },
        open_on_close: {
            type: Object,
            default: null
        },
        click_row: {
            default: null
        },
        custom_headers: {
            type: Array,
            default() {
                return []
            }
        }

    },
    data() {
        return {
            status: {ok: true, message: ""},
            internal_items: [],
            expanded: [],
            total_results: 0,
            options: {itemsPerPage: 20, page: 1},
            latest_time: {},
            internal_headers: [],
            loading: false,
        }
    },
    watch: {
        lookup: {
            handler() {
                this.throttle(
                    this.get_data,
                    5000
                )()
            },
            deep: true
        },
        sort_by_eve: {
            handler() {
                this.page = 0
                this.get_data()
            },
            deep: true
        },
        options: {
            handler() {
                this.get_data()
            },
            deep: true
        },
        refresh_resource: {
            async handler(val) {
                if (val[this.resource]) {
                    await this.get_data()
                    await this.change_refresh_resource({resource: this.resource, value: false})
                    console.log(this.refresh_resource[this.resource])
                    if (this.refresh_resource[this.resource]) {
                        await this.change_refresh_resource({resource: this.resource, value: false})
                    }

                }
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters({
            current_item: "dialog/current_item",
            domain_settings: "dialog/domain_settings",
            refresh_resource: "dialog/refresh_resource",
            user_settings: "auth/user_settings"
        }),
        sort_by_eve() {
            return this.vuetify_sort_to_eve(this.options.sortBy, this.options.sortDesc)
        },
        allow_delete() {
            if (this.domain_settings[this.resource] === undefined) {
                throw "Why is domain setting undefined"
            }
            return this.domain_settings[this.resource].item_methods.includes("DELETE")

        },
        allow_edit() {
            if (this.domain_settings[this.resource] === undefined) {
                throw "Why is domain setting undefined"
            }
            return this.domain_settings[this.resource].item_methods.includes("PUT")
        },
    },
    methods: {
        ...mapActions(
            {
                change_item: "dialog/change_item",
                reload_item: "dialog/reload_item",
                open_item: "dialog/open_item",
                set_resource: "dialog/set_resource",
                change_delete_item: "dialog/change_delete_item",
                change_refresh_resource: "dialog/change_refresh_resource",
                update_user_settings: "auth/update_user_settings"
            }
        ),
        async get_data() {
            if (this.loading) {
                return
            }

            this.loading = true

            let res = await this.api_get_items(
                {
                    resource: this.resource,
                    return_items: false,
                    page: this.options.page,
                    lookup: this.lookup,
                    sort: this.sort_by_eve,
                    disable_cache: true,
                    limit: this.options.itemsPerPage,
                    auth: this.auth,
                    // projection
                }
            )
            if (res === undefined) {
                this.status.ok = false
                this.$toasted.error("No items found")
                this.status.message = "No items found"
                return
            } else if (res["_status"] === "ERR") {
                this.$toasted.error("Something when wrong check console")
                console.warn(res)
                this.status.ok = false
                this.loading = false
                return
            }
            this.status.ok = true
            while (this.internal_items.length) {
                this.internal_items.pop();
            }
            await this.$nextTick()
            this.internal_items.push(...res._items)
            this.total_results = res._meta.total
            this.latest_time = res._meta.time
            this.loading = false
            await this.update_user_settings_internal()

        },
        edit_item(item) {
            this.open_item({
                    resource: this.resource,
                    resource_id: item._id,
                    locked_data: this.locked_data,
                    item: item,
                    mode: "edit",
                    dialog_settings: this.dialog_settings
                }
            )
        },
        click_row_internal(item) {
            if (this.click_row != null) {
                this.click_row(item)
            } else {
                this.open_item({
                    resource: this.resource,
                    resource_id: item._id,
                    locked_data: this.locked_data,
                    item,
                    mode: 'view',
                    dialog_settings: this.dialog_settings,
                    open_on_close: this.open_on_close
                })
            }

        },
        async update_user_settings_internal() {
            if (!(this.user_settings._etag && this.user_settings._id)) {
                return
            }
            let data = {
                "store": {
                    [this.resource]: {
                        "sort": {
                            "desc": this.options.sortDesc,
                            "by": this.options.sortBy
                        }
                    }
                },
                "_etag": this.user_settings._etag,
                "_id": this.user_settings._id
            }
            // TODO make this make sense
            // await this.update_user_settings(data)

        },
        create_internal_headers() {
            if (!this.domain_settings[this.resource].table_view.length) {
                console.warn(`No table view settings in resource ${this.resource}`)
            }
            let tmp = [...this.domain_settings[this.resource].table_view]

            for (let header of this.custom_headers) {
                tmp.push(
                    {
                        text: "",
                        sortable: false,
                        clickable: false,
                        value: header,
                        width: "6px",
                    }
                )
            }
            if (this.resource in this.domain_settings) {
                if (!this.hide_view) {
                    tmp.push(
                        {
                            text: "",
                            sortable: false,
                            clickable: false,
                            value: "actions_view",
                            width: "5px",
                        }
                    )
                }

                if (!this.hide_actions) {
                    if (this.allow_delete) {
                        tmp.push({
                            text: "",
                            sortable: false,
                            clickable: false,
                            value: "actions_delete",
                            width: "5px",
                        })
                    }
                    if (this.allow_edit && !this.hide_edit) {
                        tmp.push(
                            {
                                text: "",
                                sortable: false,
                                clickable: false,
                                value: "actions_edit",
                                width: "5px",
                            }
                        )
                    }

                }
            }
            for (let header of tmp) {
                if (header.locale) {
                    header.text = this.translate(header.locale)
                }
            }
            return tmp
        },

    },
    async created() {
        this.loading = true
        this.internal_value = this.value
        await this.set_resource(this.resource)
        this.internal_headers = this.create_internal_headers()
        if (this.init_sort_by.length && this.init_sort_desc.length) {
            this.options.sortBy = this.init_sort_by
            this.options.sortDesc = this.init_sort_desc
        }
        this.loading = false
        await this.get_data()

    }
}
</script>
