<template>
    <inde-table-view :resource="resource"/>
</template>

<script>
import IndeTableView from "@/shared/components/view/inde_table_view.vue";

export default {
    name: "api-keys",
    components: {IndeTableView},
    data: function () {
        return {
            resource: "api_keys",
        }
    },
    methods: {}
}
</script>

<style scoped>

</style>