import {layout_mixin} from "@/shared/mixins/layout";

let months_string = [
    "january", "february", "march", "april", "may", "june",
    "july", "august", "september", "october", "november", "december"
];

export function from_date(date) {
    let tmp = {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes()
    }
    return new DateTime(tmp)
}

export function now() {
    return from_date(new Date())
}

export function add_zeros(x) {
    let x_string = String(x)
    if (x_string.length === 1) {
        return "0" + x_string
    }
    return x_string
}

export function format({year, month, day, hour = 0, minute = 0}) {
    return `${year}-${add_zeros(month)}-${add_zeros(day)} ${add_zeros(hour)}:${add_zeros(minute)}`
}

export function format_date({year, month, day, hour = 0, minute = 0}) {
    return `${year}-${add_zeros(month)}-${add_zeros(day)}`
}

export function format_month({year, month, day, hour = 0, minute = 0}) {
    return `${year}-${add_zeros(month)}`
}


export class DateTime {
    constructor({year, month, day, hour = 0, minute = 0}) {
        if (!year) {
            throw `Year is missing ${year}`
        }
        this.internal_value = {
            year, month, day, hour, minute
        }
    }

    get date() {
        return this.internal_value
    }

    get js_date() {
        return new Date(this.year, this.month - 1, this.day, this.hour, this.minute)
    }

    get year() {
        return this.internal_value.year
    }

    get month() {
        return this.internal_value.month
    }

    get month_string() {
        return months_string[this.internal_value.month - 1]
    }

    get day() {
        return this.internal_value.day
    }

    get hour() {
        return this.internal_value.hour
    }

    get minute() {
        return this.internal_value.minute
    }

    is_equal(other_datetime) {
        return this.year === other_datetime.year & this.month === other_datetime.month & this.day === other_datetime.day
    }

    is_equal_day(other_datetime) {
        return this.year === other_datetime.year & this.month === other_datetime.month & this.day === other_datetime.day
    }

    add(days = 0, hours = 0) {
        let result = new Date(this.js_date);
        result.setDate(result.getDate() + days);
        result.setTime(result.getTime() + (hours * 60 * 60 * 1000));
        return from_date(result);
    }


    get gmt_string() {
        return this.js_date.toGMTString()
    }

    is_grater(other_datetime) {
        if (this.year !== other_datetime.year) {
            return this.year > other_datetime.year
        }
        if (this.month !== other_datetime.month) {
            return this.month > other_datetime.month
        }
        if (this.day !== other_datetime.day) {
            return this.day > other_datetime.day
        }
        if (this.hour !== other_datetime.hour) {
            return this.hour > other_datetime.hour
        }
        if (this.minute !== other_datetime.minute) {
            return this.minute > other_datetime.minute
        }
        return false
    }

    get format() {
        return format(this.internal_value)
    }

    get format_month() {
        return layout_mixin.methods.format_month(this.internal_value)
    }


}
