import {auth_axios, no_auth_axios} from "@/shared/mixins/axios_rest";
import {SchemaType} from "@/shared/mixins/schema";
import {dict_mixin} from "@/shared/mixins/dict_mixin";

export class Response {
    constructor(data) {
        this._status = data._status
        this._items = data._items
        this._error = data._error
        this._meta = data._meta
    }
}


export let api_mixin = {
    methods: {

        async api_get_aggregation_items(
            {
                resource,
                lookup = {},
                auth = true,
                disable_cache = true,
                return_items = true,
                first = false,

            }
        ) {
            if (!resource) {
                throw "Missing resource agg"
            }
            let params = {
                aggregate: lookup
            }
            return this.api_get(
                {
                    endpoint: resource,
                    params,
                    auth,
                    disable_cache,
                    return_items,
                    first
                }
            )

        },
        async api_get_items(
            {
                resource,
                lookup = {},
                sort = {},
                projection = {},
                embedded = {},
                limit = 50,
                auth = true,
                disable_cache = true,
                return_items = true,
                page = null,
                first = false,
                return_per_id = false
            }
        ) {
            if (!resource) {
                throw "Missing resource get"
            }
            let sort_fixed
            if (Object.keys(sort).length) {
                let sort_not_null = Object.fromEntries(Object.entries(sort).filter(([_, v]) => v != null));
                let sort_strings = Object.keys(sort_not_null).map(function (key) {
                    if (sort[key]) {
                        return `-${key}`
                    } else {
                        return key
                    }
                })
                sort_fixed = sort_strings.join(",")
            } else {
                sort_fixed = ""
            }

            lookup = Object.fromEntries(Object.entries(lookup).filter(([_, v]) => !!v));
            for (const [key, value] of Object.entries(lookup)) {
                if (typeof value === "object") {
                    if ("$regex" in value) {
                        if (lookup[key]["$regex"] === null) {
                            lookup[key]["$regex"] = ""
                        }
                    }
                }
            }
            let params = {
                where: JSON.stringify(lookup),
                sort: sort_fixed,
                projection: projection,
                embedded: embedded,
                max_results: limit,
                page: page,

            }
            return this.api_get(
                {
                    endpoint: resource,
                    params,
                    return_items,
                    first,
                    auth,
                    disable_cache,
                    return_per_id
                }
            )

        },
        async api_get(
            {
                endpoint,
                headers = {},
                params = {},
                first = false,
                auth = true,
                disable_cache = true,
                return_items = false,
                return_data = true,
                return_per_id = false
            }) {
            if (endpoint.startsWith("/")) {
                throw `endpoint can't start with / (endpoint ${endpoint})`
            }
            if (disable_cache) {
                headers['Cache-Control'] = 'no-cache'
            }
            let res
            if (auth) {
                res = await auth_axios.get(`/${endpoint}`, {params, headers})
            } else {
                res = await no_auth_axios.get(`/${endpoint}`, {params, headers})
            }

            if (return_items) {
                if (res === undefined) {
                    console.log(res, endpoint, params)
                    throw `Res is undefined in endpoint ${endpoint} ${params}`
                }
                if (!("data" in res)) {
                    throw `Data missing from res ${endpoint}`
                }
                if (res.data === undefined) {
                    throw `Data missing from res ${endpoint}`
                }
                if (!('_items' in res.data)) {
                    throw `_items missing from res ${endpoint} ${Object.keys(res)}`
                }
                if (first) {
                    if (process.env.VUE_APP_DEVELOPMENT) {
                        if (res.data._items.length > 1) {
                            throw `Where returned more then one fyi ${params.where} ${endpoint} ${res.data._items.length}`
                        }
                    }
                    return res.data._items[0]
                } else if (return_per_id) {
                    let tmp = {}
                    for (let d of res.data._items) {
                        tmp[d["_id"]] = d
                    }
                    return tmp
                }
                return res.data._items
            } else if (return_data) {
                return res.data
            } else {
                return res
            }
        },
        async api_post({resource, data, auth = true, return_data = true, remove_id = true}) {
            if (!resource) {
                throw "Missing resource post"
            }
            if (remove_id) {
                delete data["_id"]
                delete data["_etag"]
            }
            data = this.remove_nulls(data)
            let res
            if (auth) {
                res = await auth_axios.post(`/${resource}`, data)
            } else {
                res = await no_auth_axios.post(`/${resource}`, data)
            }
            if (return_data) {
                return res?.data
            }
            return res
        },
        async api_delete({resource, item, auth = true}) {
            if (!resource) {
                throw "Missing resource"
            }
            if (!("_id" in item && "_etag" in item)) {
                throw "Missing id or etag when deleting"
            }
            let headers = {"If-Match": item["_etag"]}
            let res
            if (auth) {
                res = await auth_axios.delete(
                    `/${resource}/${item['_id']}`,
                    {headers: headers})
            } else {
                res = await no_auth_axios.delete(
                    `/${resource}/${item['_id']}`,
                    {headers})
            }
            return res.data
        },

        async api_patch({resource, data, auth = true}) {
            // You can't null in patch
            data = Object.assign({}, data)
            if (!resource) {
                throw "Missing resource patch"
            }
            if (!("_etag" in data)) {
                throw "Missing etag"
            }
            if (!("_id" in data)) {
                throw "Missing _id"
            }
            let headers = {
                "If-Match": data._etag,
            }
            let id = data._id
            delete data._etag
            delete data._id
            for (const key in data) {
                if (key.startsWith("_")) {
                    delete data[key];
                }
            }
            let res
            if (auth) {
                res = await auth_axios.patch(`/${resource}/${id}`, data,
                    {headers})
            } else {
                res = await no_auth_axios.patch(`/${resource}/${id}`, data, {headers})
            }
            return res.data
        },

        async api_put({resource, data, auth = true}) {
            data = this.remove_nulls(Object.assign({}, data))
            if (!("_etag" in data)) {
                throw "Missing etag"
            }
            if (!("_id" in data)) {
                throw "Missing etag"
            }
            let id = data._id
            let headers = {
                "If-Match": data._etag,
            }
            for (const key in data) {
                if (key.startsWith("_")) {
                    delete data[key];
                }
            }
            let res
            if (auth) {
                res = await auth_axios.put(`/${resource}/${id}`, data, {headers: headers})
            } else {
                res = await no_auth_axios.put(`/${resource}/${id}`, data, {headers: headers})
            }
            return res.data
        },
        async get_media(value) {
            if (!value.id) {
                throw "missing id in file"
            }
            if (value.file) {
                console.log("File already exists")
                return
            }
            let response = await this.api_get(
                {
                    endpoint: `media_data/${value.id}`
                }
            )
            if (response?._status === "OK") {
                value.file = response.data
            } else {
                this.$toasted.error(`Could not find file with id ${value.id}`)
            }
            return value
        },
        async get_schema(resource) {
            if (!resource) {
                throw "No resource in get schema " + resource
            }

            let res = await this.api_get({
                endpoint: "schema/" + resource,
                auth: false,
                return_data: false
            })
            if (res.status === 404) {
                this.$toasted.error(`Could not find schema ${resource}`)
            }
            if (!("data" in res)) {
                throw "Missing data from res"
            }
            return new SchemaType({...res.data, resource: resource})
        },
        async save_resource(data, domain_settings, resource) {
            if (!resource) {
                throw "Resource missing in save resource"
            }
            if (!(resource in domain_settings)) {
                throw "Resource not in Domain settings in save resource"
            }

            if (this.is_object_id(data._id) && data._etag) {
                this.remove_read_only_fields(data, domain_settings[resource].schema)
                this.remove_nulls(data)
                return await this.api_put({resource, data})
            } else {
                return await this.api_post({resource, data})
            }

        },
        remove_read_only_fields(data, domain_settings_schema) {
            for (let key of Object.keys(domain_settings_schema)) {
                let schema = domain_settings_schema[key]
                if (schema.readonly) {
                    delete data[key]
                } else if (data[key] == null) {
                    delete [key]
                } else if (schema.is_list && Array.isArray(data[key])) {
                    if (schema.is_object) {
                        for (let val of data[key]) {
                            this.remove_read_only_fields(val, schema.schema)
                        }
                    }
                } else if (schema.is_object) {
                    console.log("schema is object", data, schema.name)
                }
            }
        },
        remove_nulls(obj) {
            const newObj = {};
            Object.entries(obj).forEach(function ([k, v]) {
                if (Array.isArray(v)) {
                    if (v.length !== 0) {
                        newObj[k] = v
                    }
                } else if (v === Object(v)) {
                    if (Object.keys(v).length !== 0) {
                        let cleaned_object = api_mixin.methods.remove_nulls(v);
                        if (Object.keys(cleaned_object).length !== 0) {
                            newObj[k] = cleaned_object
                        }
                    }
                } else if (v != null) {
                    newObj[k] = obj[k];
                }
            });
            return newObj;
        },
        remove_fields_not_in_the_schema(obj, schema) {
            const newObj = {};
            Object.entries(obj).forEach(function ([k, v]) {
                if (Array.isArray(v)) {
                    if (v.length !== 0) {
                        newObj[k] = v
                    }
                } else if (dict_mixin.methods.is_object(v)) {
                    if (Object.keys(v).length !== 0) {
                        let cleaned_object = api_mixin.methods.remove_fields_not_in_the_schema(v, schema[k].schema);
                        if (Object.keys(cleaned_object).length !== 0 && Object.keys(schema).includes(k)) {
                            newObj[k] = cleaned_object
                        }
                    }
                } else if (Object.keys(schema).includes(k)) {
                    newObj[k] = obj[k];
                }
            });
            return newObj;
        },
        is_object_id(value) {
            if (typeof value !== "string") {
                return false
            }
            let tmp = value.match(/^[0-9a-fA-F]{24}$/)
            return tmp.length
        },
        async get_image_url(file_id) {
            let url = `media_url/${file_id}`
            let tmp = await this.api_get(
                {
                    endpoint: url
                }
            )
            if (tmp._status === "OK") {
                return tmp.url
            }
            return null

        },
    }
}