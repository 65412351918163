<template>
    <div>
        <inde-card-view :items="customers" :resource="resource" :save="save">

        </inde-card-view>

    </div>
</template>

<script>
import IndeCardView from "@/shared/components/view/inde_card_view.vue";
import {api_mixin} from "@/shared/mixins/api_mixin";

export default {
    name: "customers",
    components: {IndeCardView},
    mixins: [api_mixin],
    data: function () {
        return {
            resource: "customers",
            customers: [],

        }
    },
    methods: {
        async save(item) {
            console.log(item)
            let res = await this.create_customer(item)
            if (res.ok) {
                this.$toasted.success("Created customer")
            } else {
                this.$toasted.error(res.msg)
            }

        },

        async select(customer_id) {
            this.selected_customer = customer_id
            this.dialog = true
        },
        deselect() {
            this.selected_customer = null
            this.dialog = false
        },


    },
    async created() {
        this.customers = await this.api_get_items({resource: this.resource})
    },

}
</script>

<style scoped>

</style>