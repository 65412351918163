export const variables_store = {
    namespaced: true,
    state() {
        return {
            links: {
                admin: [
                    {
                        url: "/invoice",
                        name: "Invoice",
                        icon: "",
                        locale: "invoice"
                    },
                    {
                        url: "/admin",
                        name: "Admin",
                        icon: "",
                        locale: "admin"
                    },

                ],
                common: [
                    {
                        url: "/dashboard",
                        name: "Dashboard",
                        icon: "mdi-view-dashboard",
                        locale: "dashboard"
                    }
                ]
            }
        }
    }
}