<template>
    <div>
        <v-form v-model="valid">
            <InputSelect
                v-model="selected.inverter_id"
                resource="inverters"
                item-text="name"
                item-value="_id"
            />
            <InputSelect
                v-model="selected.sample_type"
                :rules="rules"
                :items="sample_types">
                Sample Type
            </InputSelect>
            <v-row>
                <v-col>
                    <inde-stepper-date
                        v-model="selected.date_from"
                        label="DateFrom"
                    />
                </v-col>
                <v-col>
                    <inde-stepper-date
                        v-model="selected.date_to"
                        label="DateFrom"
                    />
                </v-col>
            </v-row>
        </v-form>
        <v-btn v-on:click="fetch_data" :disabled="!valid"> Fetch data</v-btn>
    </div>
</template>

<script>
import {dashboard_mixin} from "@/mixins/dashboard";
import {api_mixin} from "@/shared/mixins/api_mixin";
import IndeStepperDate from "@/shared/components/dialogs/stepper_date";
import Input_select from "@/shared/components/dialogs/input_select";
import InputSelect from "@/shared/components/dialogs/input_select.vue";
import {DateTime, now} from "@/shared/helpers/date_helper";


export default {
    name: "Dash",
    mixins: [dashboard_mixin, api_mixin],
    components: {
        InputSelect,
        Input_select,
        IndeStepperDate,
    },
    data: function () {
        return {
            layout: {
                plot_bgcolor: '#d3d3d3',
                paper_bgcolor: '#d3d3d3',
            },
            valid: false,
            rules: [
                v => !!v || "Can't be empty",
            ],
            data: [],
            inverters: [],
            sample_types: [
                "daily_data",
                "five_min_data",
                "monthly_data",
                "ten_min_data"],
            selected: {
                inverter_id: null,
                sample_type: "daily_data",
                date_from: new DateTime({year: 2021, month: 1, day: 1}),
                date_to: now()
            }
        }
    },
    computed: {
        computed_data() {
            if (this.data.values.length > 0) {

                return this.create_data(this.data, "timestamp", "value", "scatter")
            }
            return [this.create_data([], "timestamp", "value", "scatter")]
        }
    },
    methods: {
        async fetch_data() {
            this.d = await this.get_raw_data(this.selected)

        },

    },
    async created() {
        this.inverters = await this.api_get_items(
            {
                resource: "inverters",
                lookup: {}
            }
        )
        if (this.inverters.length > 0) {
            this.selected.inverter_id = this.inverters[0].inverter_id
        }
    }
}
</script>

<style scoped>

</style>