import {mapGetters, mapState} from "vuex";
import {DateTime, now, add_zeros, format, format_month, format_date} from "@/shared/helpers/date_helper";
import {dict_mixin} from "@/shared/mixins/dict_mixin";

export let layout_mixin = {
    data: function () {
        return {
            internal_loading: true,
            width: window.innerWidth,
            drawer: false,
            locales: {
                'se': 'se',
                'en': 'gb',
            },
            default_colors: {
                primary: "#3f51b5",
                secondary: "#00bcd4",
                accent: "#cddc39",
                error: "#f44336",
                warning: "#e91e63",
                info: "#03a9f4",
                success: "#4caf50",
                background: "#FFFFFF"
            }
        }
    },
    methods: {
        format,
        format_month,
        format_date,
        format_smart(date) {
            let today = now()
            let d = new DateTime(date)
            let at = this.translate("at", false)
            let day = add_zeros(d.day)
            let min = add_zeros(d.minute)
            let hour = add_zeros(d.hour)
            let locale = this.$i18n.locale


            if (today.is_equal_day(d)) {
                let t = this.translate("today", true)
                return `${t} ${at} ${hour}:${min}`
            } else if (today.add(1).is_equal_day(d)) {
                let t = this.translate("tomorrow", true)
                return `${t} ${at} ${hour}:${min}`
            } else if (today.add(-1).is_equal_day(d)) {
                let t = this.translate("yesterday", true)
                return `${t} ${at} ${hour}:${min}`
            } else {
                let m = this.translate(d.month_string, true)
                if (locale === "se") {
                    return `${hour}:${min} ${day} ${m}`
                }
                return `${m} ${day} ${at} ${hour}:${min}`
            }
        },

        translate(key, capitalize = true) {
            if (typeof key !== "string") {
                return "translation has to be a string " + key
            }
            if (key.length === 0) {
                throw "translation can't be empty"
            }
            let translation
            if (key.startsWith(process.env.VUE_APP_PROJECT_NAME + ".")) {
                translation = this.$te(key)
            } else if (key.startsWith("common.")) {
                translation = this.$te(key)
            }
            if (!translation) {
                translation = this.$te("common." + key)
                if (translation) {
                    key = "common." + key
                } else {
                    translation = this.$te(process.env.VUE_APP_PROJECT_NAME + "." + key)
                    if (translation) {
                        key = process.env.VUE_APP_PROJECT_NAME + "." + key
                    }
                }
            }
            if (translation) {
                translation = this.$t(key)
            } else {
                console.log("missing translation ", `<${key}>`, translation)
                translation = key

            }
            if (capitalize) {
                return translation.charAt(0).toUpperCase() + translation.slice(1)
            }
            return translation
        },
        format_string(str, data) {
            let re = /{([^{}]+)}/g;

            return str.replace(/{([^{}]+)}/g, function (match, val) {
                let prop = data;
                val.split('.').forEach(function (key) {
                    prop = prop[key];
                });

                return prop;
            });
        },
        toast_results(res, success_message) {
            success_message = success_message ? success_message : "Success"
            if (typeof res?.then === 'function') {
                console.warn("You maybe forgot an await?")
                return
            }
            if (res === undefined) {
                console.warn("Res is undefined")
                return
            }
            if (res["_status"] === "OFFLINE") {
                this.$toasted.error(
                    "Web app offline"
                )
            }
            if (res["_status"] === "ERR") {
                if ("_error" in res) {
                    if ("message" in res._error) {
                        console.warn(res._error.message)
                        this.$toasted.error(res._error.message, {"duration": 10000})
                    }
                }
                if ("_issues" in res) {
                    this.$toasted.error(JSON.stringify(res._issues))
                }
                if ("_locale_message" in res) {
                    this.$toasted.error(this.translate(res._locale_message, true))
                }
                if ("_issue" in res) {
                    this.$toasted.error(res._issue)
                }
            } else if (299 < res.status) {
                this.$toasted.error(res.statusText)
            } else {
                this.$toasted.success(success_message)
            }
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        set_theme(color_settings = {}) {
            let custom_colors = {}
            if (dict_mixin.methods.is_object(color_settings)) {
                for (let key in this.default_colors) {
                    if (key in color_settings) {
                        custom_colors[key] = color_settings[key]
                    } else {
                        custom_colors[key] = this.default_colors[key]
                    }
                }
            } else {
                custom_colors = this.default_colors
            }
            for (let key in custom_colors) {
                this.$vuetify.theme.themes.light[key] = custom_colors[key]
            }
            this.$vuetify.theme.dark = false
        },
        debounce(fn, delay) {
            // Use this functions like
            // this.debounce(this.get_data, 100)(args, of, the, function)
            let timeoutId = 0
            return (...args) => {
                clearTimeout(timeoutId)
                timeoutId = setTimeout(() => fn(...args), delay)
            }
        },
        throttle(fn, limit) {
            // Use this functions like
            // this.throttle(this.get_data, 100)(args, of, the, function)
            let throttling = false
            return (...args) => {
                if (!throttling) {
                    throttling = true
                    setTimeout(() => throttling = false, limit)
                    return fn(...args)
                }
            }
        }
    },
    computed: {
        ...mapGetters({current_user: "auth/current_user"}),
        small_screen() {
            return this.width < 600
        },
        col_per_row() {
            if (this.small_screen) {
                return 1
            } else {
                return 2
            }
        }
    },
    created() {
        window.addEventListener('resize', () => {
            this.width = window.innerWidth
        })
    }
}
