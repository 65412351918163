import {check_refresh_token_valid, check_token_valid} from "@/shared/mixins/token";
import store from "@/store";

export function get_before_router(router) {
    return async function before_router(to, from, next) {
        if (to.name === "Activate") {
            next()
            return
        }
        let logged_in = check_token_valid()

        if (logged_in === false) {
            let refresh_token_valid = check_refresh_token_valid()
            if (refresh_token_valid) {
                await store.dispatch("auth/refresh_token")
                next()
            }
        }
        if (to.matched.some(record => record.meta.requiresAuth)) {
            // this route requires auth, check if logged. If
            // not, redirect to login page.
            if (logged_in === null) {
                window.location = "/"
            }
            let valid_token = check_token_valid()

            if (!valid_token) {
                let success = await store.dispatch("auth/refresh_token")

                if (success) {
                    if (to.matched.some(record => record.meta.requiresAdmin)) {
                        if (store.getters["auth/current_user"]?.is_admin === true) {
                            next()
                        }
                    } else {
                        next("/")
                    }
                } else {
                    await store.dispatch("auth/send_to_login", router.currentRoute)
                }
            } else {
                if (to.matched.some(record => record.meta.requiresAdmin)) {
                    if (store.getters["auth/current_user"]?.is_admin === true) {
                        next()
                    }
                } else {
                    next()
                }
            }
        } else if (to.matched.some(record => record.meta.requiresAdmin)) {
            let valid_token = check_token_valid()

            if (!valid_token) {
                let success = await store.dispatch("auth/refresh_token")
                if (success) {
                    next()
                } else {
                    await store.dispatch("auth/send_to_login", router.currentRoute)
                }
            } else {
                next()
            }

        } else {
            next() // does not require auth, make sure to always call next()!
        }
    }
}
