import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import {vuetify} from '@/shared/init';
import store from '@/store/index'
import i18n from "@/shared/i18n";

new Vue({
    vuetify,
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
