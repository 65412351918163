<template>
    <v-container fluid>
        <slot name="create_new">
            <CreateNew
                v-if="resource"
                :resource="resource"
                :hide_create_new="hide_create_new"
                :default_data="default_data"
                :locked_data="locked_data"
            />
        </slot>
        <v-row
            justify="center" align="center"
            v-for="i in Math.ceil(internal_items.length / cols_per_row)"
            v-bind:key="i">
            <v-col
                :cols="12/cols_per_row"
                v-for="item in internal_items.slice((i - 1) * cols_per_row, i * cols_per_row)"
                v-bind:key="item._id"
            >
                <slot name="item" :item="item">
                    <CustomComponent
                        v-if="domain_settings[resource].custom_card_component"
                        :value="item"
                        :custom_component="domain_settings[resource].custom_card_component"
                        :domain_settings="domain_settings[resource]"
                        dotted_path=""
                    />
                    <v-card v-else @click="open_item_internal(item, 'view')">
                        <v-card-text>
                            <v-row v-for="(row, index) in item_layout" v-bind:key="index">
                                <v-col cols="6">
                                    <template v-if="row[0]">
                                        <div
                                            class="font-weight-bold"
                                        >
                                            {{ row[0].text }}
                                        </div>
                                        <IndeValue
                                            :item="item"
                                            :header_item="row[0]"
                                            :resource="resource"
                                        />
                                    </template>
                                </v-col>
                                <v-col cols="6">
                                    <template v-if="row[1]">
                                        <div
                                            class="font-weight-bold"
                                        >
                                            {{ row[1].text }}
                                        </div>
                                        <IndeValue
                                            :item="item"
                                            :header_item="row[1]"
                                            :resource="resource"
                                        />
                                    </template>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </slot>
            </v-col>
        </v-row>
        <v-data-footer
            :items-per-page-options="[20, 50]"
            :pagination="pagination"
            :options.sync="options"
        />
    </v-container>
</template>

<script>
import {layout_mixin} from "@/shared/mixins/layout";
import CreateNew from "@/shared/components/dialogs/create_new_btn.vue";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {mapActions, mapGetters} from "vuex";
import {dialog_mixin} from "@/shared/mixins/dialog_mixin";
import IndeValue from "@/shared/components/view/inde_value.vue";

export default {
    name: "inde-card-view",
    components: {
        CustomComponent: () => import("@/shared/components/dialogs/custom_components/index.vue"),
        IndeValue,
        CreateNew
    },
    mixins: [layout_mixin, api_mixin, dialog_mixin],
    props: {
        lookup: {
            type: Object,
            default: () => {
            }
        },
        default_data: {
            type: Object,
            default: () => {
            }
        },
        locked_data: {
            type: Object,
            default: () => {
            }
        },
        click_row: {
            type: Function,
            default: null
        },
        resource: {
            type: String
        },
        hide_create_new: {
            type: Boolean,
            default: false
        },
        init_sort_by: {
            type: Array,
            default: () => []
        },
        init_sort_desc: {
            type: Array,
            default: () => []
        },
        actions: {
            type: Boolean,
            default: false
        },
        expandable: {
            type: Boolean,
            default: false
        },
        hide_edit: {
            type: Boolean,
            default: false
        },
        cols: {
            type: Number,
            default: 2
        },
        small_cols: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            pag_val: null,
            internal_items: [],
            pagination: {page: 1, itemsPerPage: 20, pageStart: 1, pageStop: 2, pageCount: 1, itemsLength: 0},
            status: {ok: true, message: ""},
            expanded: [],
            total_results: 0,
            options: {itemsPerPage: 20, page: 1},
            latest_time: {},
            internal_headers: [],
            loading: false,

        }
    },
    watch: {
        items(val) {
            this.internal_items = val
        },
        lookup() {
            this.get_items()
        },
        async refresh_resource(val) {
            if (val[this.resource]) {
                await this.get_items()
                await this.change_refresh_resource({resource: this.resource, value: false})
            }

        },
        options: {
            handler() {
                this.pagination.itemsPerPage = this.options.itemsPerPage
                this.get_items()
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters({
            domain_settings: "dialog/domain_settings",
            refresh_resource: "dialog/refresh_resource"
        }),
        item_layout() {
            let domain_settings = this.domain_settings
            let resource = this.resource
            if (!(resource in domain_settings)) {
                return []
            }
            return this.get_card_view_layout(domain_settings[resource].card_view)
        },
        sort_by_eve() {
            return this.vuetify_sort_to_eve(this.options.sortBy, this.options.sortDesc)
        },
        cols_per_row() {
            if (this.small_screen) {
                return this.small_cols
            } else {
                return this.cols
            }
        }
    },
    methods: {
        ...mapActions(
            {
                set_resource: "dialog/set_resource",
                change_item: "dialog/change_item",
                reload_item: "dialog/reload_item",
                change_mode: "dialog/change_mode",
                open_item: "dialog/open_item",
                change_delete_item: "dialog/change_delete_item",
                change_refresh_resource: "dialog/change_refresh_resource"
            }
        ),
        open_item_internal(item, mode) {
            this.open_item({
                item,
                resource_id: item._id,
                resource: this.resource,
                locked_data: this.locked_data,
                mode
            })
        },
        async get_items() {
            if (this.loading) {
                return
            }
            this.loading = true
            let res = await this.api_get_items(
                {
                    resource: this.resource,
                    return_items: false,
                    page: this.options.page,
                    lookup: this.lookup,
                    sort: this.sort_by_eve,
                    disable_cache: true,
                    limit: this.options.itemsPerPage,
                    auth: this.auth
                }
            )
            if (res === undefined) {
                this.status.ok = false
                this.$toasted.error("No items found")
                this.status.message = "No items found"
                return
            } else if (res["_status"] === "ERR") {
                this.status.ok = false
                this.toast_results(res)
                console.warn(res)
                return
            }
            while (this.internal_items.length) {
                this.internal_items.pop();
            }
            await this.$nextTick()
            this.internal_items.push(...res._items)
            this.pagination.itemsLength = res._meta.total
            this.pagination.pageStart = (res._meta.page - 1) * res._meta.max_results
            this.pagination.pageStop = (res._meta.page) * res._meta.max_results
            this.latest_time = res._meta.time
            this.loading = false

        },
    },
    async created() {
        if (this.resource !== null) {
            await this.set_resource(this.resource)
        }
        if (this.init_sort_by.length && this.init_sort_desc.length) {
            this.options.sortBy = this.init_sort_by
            this.options.sortDesc = this.init_sort_desc
        }
        await this.get_items()

    }
}
</script>

<style scoped>

</style>