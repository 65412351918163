import VueJwtDecode from "vue-jwt-decode";
import {check_token_valid, check_refresh_token_valid} from "@/shared/mixins/token";
import {api_mixin} from "@/shared/mixins/api_mixin";
import VuexPersistence from "vuex-persist";
import {dict_mixin} from "@/shared/mixins/dict_mixin";

export function create_persistent_storage(modules = ["auth"]) {
    if (!process.env.VUE_APP_PROJECT_NAME) {
        console.warn("No project name")
    }
    return new VuexPersistence({
            key: process.env.VUE_APP_PROJECT_NAME,
            restoreState: function (key) {
                let json = null
                try {
                    return JSON.parse(window.localStorage.getItem(key))

                } catch (e) {
                    console.log("json", json)
                    console.log("key", key)
                    console.log("Error in restore state", e)
                }
                return {}
            },
            saveState: (key, state) => {
                window.localStorage.setItem(key, JSON.stringify(state))
            },
            modules: modules
        },
    )
}

export const auth_store = {
    namespaced: true,
    state: () => ({
        authenticate: {
            token: null, refresh_token: null
        },
        decoded_token: null,
        decoded_refresh_token: null,
        user: {
            user: null,
            loaded: false
        },
        next: {name: "Home"},
        locale: "en",
        user_settings: null

    }),
    mutations: {
        mutate_next(state, next) {
            if (next === undefined) {
                state.next.name = "Home"
            } else if ("name" in next) {
                state.next.name = next.name
            } else {
                state.next.name = "Home"
            }
        },
        invalidate_token(state) {
            state.authenticate = {token: null, refresh_token: null}
            state.decoded_token = null
            state.user.user = null
            state.user.loaded = false
            state.next = {name: "Home"}
            state.user_settings = null
        },
        insert_token(state, data) {
            if (data != null) {
                state.authenticate = data
                state.decoded_token = VueJwtDecode.decode(state.authenticate.token);
                state.decoded_refresh_token = VueJwtDecode.decode(state.authenticate.refresh_token);

            } else {
                console.warn("Invalid mutation insert token", data)
            }

        },
        set_attribute_user(state, {attribute, value}) {
            state.user[attribute] = value
        },
        set_property(state, {dotted_path, value, copy = false}) {
            if (copy) {
                value = {...value}
            }
            dict_mixin.methods.deep_set(state, dotted_path, value)
        },
    },
    actions: {
        async load_user_settings({commit, state}, {i18n = null, vuetify = null, force = false}) {
            let user_id = state.user?.user?._id
            if (!user_id) {
                console.log("User is not logged in")
                return
            }
            let user_settings
            let load_user_settings = false
            if (dict_mixin.methods.is_object(state.user_settings)) {
                if (force) {
                    load_user_settings = true
                } else if (!(state.user_settings)) {
                    load_user_settings = true
                } else if (!('_etag' in state.user_settings)) {
                    load_user_settings = true
                } else if (!('_id' in state.user_settings)) {
                    load_user_settings = true
                } else if (state.user_settings !== user_id) {
                    load_user_settings = true
                }
            } else {
                load_user_settings = true
            }

            if (load_user_settings) {
                user_settings = await api_mixin.methods.api_get_items(
                    {
                        resource: "user_settings",
                        lookup: {
                            "user": state.user.user._id
                        },
                        first: true
                    }
                )
            } else {
                user_settings = state.user_settings
            }

            if (user_settings !== undefined) {
                if (user_settings.locale && (i18n !== null)) {
                    i18n.locale = user_settings.locale
                }
                if (user_settings.dark && (vuetify !== null)) {
                    vuetify.theme.dark = user_settings.dark
                }
            } else {
                user_settings = {
                    user: state.user.user._id,
                    locale: "se",
                    dark: true
                }

                vuetify.theme.dark = user_settings.dark
                let res = await api_mixin.methods.api_post({
                        resource: "user_settings",
                        data: user_settings
                    }
                )
                user_settings["_id"] = res["_id"]
                user_settings["_etag"] = res["_etag"]
            }
            commit("set_property", {dotted_path: "user_settings", value: user_settings})
        },
        async update_user_settings({commit, getters, dispatch}, data) {
            if (getters["current_user"] === null) {
                console.log("Current users is null")
                return
            }
            let res = await api_mixin.methods.api_patch(
                {
                    resource: "user_settings",
                    data: {
                        ...data,
                        _etag: getters["user_settings"]["_etag"],
                        _id: getters["user_settings"]["_id"]
                    }
                }
            )
            if (res._status === "OK") {
                for (let key in data) {
                    if (!key.startsWith("_")) {
                        commit("set_property", {dotted_path: `user_settings.${key}`, value: data[key]})
                    }
                }
                commit("set_property", {dotted_path: "user_settings._etag", value: res._etag})
            } else {
                console.log("Something went wrong while updating user_settings", data)
                dispatch("load_user_settings", {force: true})
            }
        },
        async change_locale({state, commit, dispatch}, {locale, i18n}) {
            if (i18n) {
                i18n.locale = locale
                await dispatch("update_user_settings", {locale})
            }
        },
        async change_dark_mode({state, commit, dispatch}, {dark, vuetify}) {
            if (vuetify) {
                vuetify.theme.dark = dark
                await dispatch("update_user_settings", {dark})
            }
        },
        send_to_login(
            {commit},
            {next, id_type = "google", params = [], args = "", return_url = false}) {
            commit("mutate_next", next)
            let path = `${process.env.VUE_APP_BACKEND_URL}/api/authorize/init/${id_type}`
            if (params.length) {
                path = path + "/" + params.join("/")
            }
            path = path + args
            if (return_url) {
                return path
            }
            console.log("redirecting to path", path)
            window.location = path
        },
        logout({commit}) {
            commit("invalidate_token")
        },
        async refresh_token({state, commit, dispatch}, toasted = null) {
            let decoded_token;
            try {
                decoded_token = VueJwtDecode.decode(state.authenticate.token);
            } catch (e) {
                decoded_token = null
            }
            if (decoded_token !== null) {
                let valid_token = check_token_valid()
                if (!valid_token) {
                    if (!check_refresh_token_valid()) {
                        await dispatch("send_to_login", {next: {"name": "Home"}})
                    }

                    let response = await api_mixin.methods.api_post(
                        {
                            resource: "refresh_token",
                            data: {
                                "refresh_token": state.authenticate.refresh_token
                            },
                            auth: false

                        }
                    )
                    if (response._status === "offline") {
                        if (toasted !== null) {
                            toasted.error("Web app is offline")
                        }
                        throw "Web app is offline"
                    }
                    if (response._status === "OK") {
                        commit("insert_token", response)
                    } else {
                        console.warn("Unable to load token")
                        dispatch("send_to_login", {next: {name: "Home"}})
                        return false
                    }
                }
                return true
            } else {
                console.log("Decoded token is null")
                return false
            }
        }
        ,
        async load_user({commit}) {
            let user_data = await api_mixin.methods.api_get(
                {
                    endpoint: "me",
                    return_data: true,
                }
            )
            if (user_data === null) {
                commit("invalidate_token")
                return false
            }
            commit("set_attribute_user", {attribute: "user", value: user_data})
            commit("set_attribute_user", {attribute: "loaded", value: true})
            return true
        }
    },
    getters: {
        next(state) {
            return state.next
        },
        decoded_token(state) {
            return state.decoded_token
        },
        decoded_refresh_token(state) {
            return state.decoded_refresh_token
        },
        rawToken(state) {
            if (state.authenticate.token === null) {
                return undefined
            }
            let valid_token = check_token_valid()
            if (valid_token) {
                return state.authenticate.token
            } else {
                return null
            }
        },
        raw_token(state) {
            if (state.authenticate.token === null) {
                return undefined
            }
            let valid_token = check_token_valid()
            if (valid_token) {
                return state.authenticate.token
            } else {
                return null
            }
        },
        current_user(state) {
            if (!state.user.loaded || state.user.user === null) {
                return null
            }
            return state.user.user
        },
        user_settings(state) {
            return state.user_settings
        }
    }
}
