<template>
    <div>
        <pdf-viewer v-model="pdf_open" v-if="pdf_open" :url="selected_invoice.file_path">
        </pdf-viewer>
        <IndeDataTable :resource="resource">
            <template #item.actions="{item}">
                <v-btn
                        @click="open_pdf({item, new_window:false})"
                        icon
                >
                    <v-icon>

                    </v-icon>
                </v-btn>
            </template>
        </IndeDataTable>


    </div>
</template>

<script>
import PdfViewer from "@/views/components/custom/pdf-viewer";
import IndeDataTable from "@/shared/components/view/inde_table_view";
import {api_mixin} from "@/shared/mixins/api_mixin";

export default {
    name: "invoice",
    components: {IndeDataTable, PdfViewer},
    mixins: [api_mixin],
    data() {
        return {
            resource: "invoices",
            customer_id: null,
            year: null,
            selected_invoice: null,
            dialog_open: false,
            pdf_open: false,
            invoices: [],
        }
    },
    watch: {
        async customer_id() {
            await this.reload_invoices()
        },
        async year() {
            await this.reload_invoices()
        },

    },
    methods: {
        async reload_invoices() {
            this.invoices = await this.api_get_items(
                {
                    resource: "invoices",
                    lookup: {
                        customer: this.customer_id,
                        year: this.year
                    }
                }
            )
        },
        open_dialog(item) {
            this.selected_invoice = item
            this.dialog_open = true
        },
        open_pdf({item, new_window}) {
            this.selected_invoice = item
            if (new_window) {
                window.open(item.file_path)
            } else {
                this.pdf_open = true
            }
        }
    }

}
</script>

<style scoped>

</style>