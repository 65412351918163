<template>
    <div>
        <v-app-bar app>
            <v-btn text to="/">
                Home
            </v-btn>
            <template v-if="current_user !== null">
                <v-col :align="'start'">
                    <div class="white-space: nowrap;">
                        <template v-for="link in links">
                            <v-menu
                                v-if="link.dropdown"
                                v-model="link.dropdown_open"
                                offset-y
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        class="mx-1"
                                    >
                                        {{ link.name }}
                                        <v-icon
                                            v-text="'mdi-chevron-down'"
                                            :small="$vuetify.breakpoint.mdAndDown"
                                        />
                                    </v-btn>

                                </template>
                                <v-list
                                    dense
                                    style="padding: 0"
                                >
                                    <div
                                        v-for="(item, index) in link.dropdown"
                                        :key="index"
                                    >
                                        <v-list-item
                                            class="font-weight-regular text-capitalize"
                                            :to="item.url"
                                        >
                                            <v-list-item-title>
                                                {{ item.name }}
                                            </v-list-item-title>
                                        </v-list-item>
                                    </div>
                                </v-list>
                            </v-menu>
                            <v-btn
                                v-else
                                :to="link.url"
                                :small="$vuetify.breakpoint.mdAndDown"
                                text
                                class="hidden-sm-and-down"
                            >
                                {{ translate(link.locale) }}
                            </v-btn>
                        </template>
                    </div>
                </v-col>
                <v-col align="end" cols="2" v-if="$vuetify.breakpoint.mdAndUp">
                    <slot name="extra_links">
                    </slot>
                </v-col>

                <v-menu offset-y min-width="100px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"

                        >
                            <v-avatar left size="40">
                                <img
                                    v-if="current_user.picture"
                                    alt="User Picture"
                                    :src="current_user.picture"
                                >
                            </v-avatar>
                        </v-btn>
                    </template>
                    <v-list rounded min-width="200px">
                        <v-list-item
                            v-for="(language, index) in locales"
                            :key="'language_' + index"
                            @click="change_locale({locale:index, i18n:$i18n})"
                        >
                            <v-list-item-avatar
                                class="pb-1"
                                tile
                            >
                                <CountryFlag
                                    :country="language"
                                    size="normal"
                                />

                            </v-list-item-avatar>
                            <v-list-item-title>{{ $t(`common.${index}`) }}</v-list-item-title>
                            <v-list-item-avatar
                                tile
                            >
                                <v-icon v-if="index === $i18n.locale">
                                    mdi-check-circle
                                </v-icon>
                            </v-list-item-avatar>

                        </v-list-item>
                        <v-list-item>
                            <div style="border: 4px black;">
                                <v-btn
                                    icon
                                    v-if="$vuetify.theme.dark"
                                    @click="change_dark_mode({dark: false, vuetify: $vuetify})"
                                >
                                    <v-icon>
                                        mdi-theme-light-dark
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    icon v-else
                                    @click="change_dark_mode({dark: true, vuetify: $vuetify})"
                                >
                                    <v-icon>
                                        mdi-theme-light-dark
                                    </v-icon>
                                </v-btn>
                            </div>

                        </v-list-item>
                        <v-list-item v-on:click="logout" dense>
                            <v-list-item-title>
                                Log out
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <v-app-bar-nav-icon @click="drawer = drawer === false" class="hidden-md-and-up"/>
            </template>
            <template v-else>
                <v-col align="end">
                    <v-btn icon @click="() => redirect('github')" v-if="!hide_github_login">
                        <v-icon>
                            mdi-github
                        </v-icon>
                    </v-btn>
                    <v-btn icon @click="() => redirect('sma')" v-if="show_sma_login">
                        <v-icon>
                            mdi-solar-panel
                        </v-icon>
                    </v-btn>
                    <v-btn icon @click="() => redirect('google')">
                        <v-icon>
                            mdi-google
                        </v-icon>
                    </v-btn>
                </v-col>
            </template>
        </v-app-bar>
        <NavigationDrawer v-model="drawer"></NavigationDrawer>

    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {layout_mixin} from "@/shared/mixins/layout";
import {token_mixin} from "@/shared/mixins/token";
import NavigationDrawer from "@/shared/components/layout/NavigationDrawer.vue";
import CountryFlag from 'vue-country-flag'

export default {
    name: "TopBar",
    components: {CountryFlag, NavigationDrawer},
    mixins: [layout_mixin, token_mixin],
    props: {
        hide_github_login: {
            type: Boolean,
            default: false
        },
        show_sma_login: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters({
            current_user: "auth/current_user",
            links: "variables/links"
        }),
        ...mapState({
            authenticate: 'auth/authenticate'
        }),
    },
    methods: {
        ...mapActions({
            _logout: 'auth/logout',
            send_to_login: "auth/send_to_login",
            change_locale: "auth/change_locale",
            change_dark_mode: "auth/change_dark_mode"
        }),
        redirect(id_type) {
            this.send_to_login({next: this.$router.currentRoute, id_type})
        },

        logout() {
            this._logout()
            if (this.$router.currentRoute.name !== "Home") {
                this.$router.push({name: "Home"})
            }
            location.reload();
        }
    },
    created() {
    }
}
</script>

<style scoped>

</style>

