<template>
    <div>
        <v-menu
            ref="menu"
            v-model="menu"
            class="no-scroll"
            :close-on-content-click="false"
            min-width="auto"
            max-height="1000px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    :value="format_date_time"
                    :label=label
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :disabled="disabled"
                ></v-text-field>
            </template>
            <v-card>
                <v-card-text>
                    <v-text-field
                        v-if="time_or_date === 'time'"
                        :value="format_date_time"
                        :label=label
                        :disabled="disabled"
                        readonly
                        prepend-icon="mdi-calendar"
                    ></v-text-field>
                    <v-row>
                        <v-date-picker
                            v-if="time_or_date === 'date'"
                            :value="internal_date"
                            :active-picker.sync="activePicker"
                            :max="internal_max"
                            :min="internal_min"
                            :first-day-of-week="1"
                            @change="save_date"
                        />
                        <v-time-picker
                            v-else-if="time_or_date === 'time'"
                            :value="format_date_time"
                            class="mt-16"
                            format="24hr"
                            no-title
                            scrollable
                            @click:hour="save_hour"
                            @click:minute="save_minute"
                        ></v-time-picker>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-menu>
    </div>
</template>


<script>
import {mapGetters} from "vuex";
import {DateTime, format, format_date} from "@/shared/helpers/date_helper";

export default {
    name: "IndeStepperDate",
    props: {
        value: {},
        label: {
            type: String
        },
        max: {
            type: String,
            default: null
        },
        min: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean
        },
        time: {
            type: Boolean,
            default: false
        },
        date: {
            type: Boolean,
            default: true
        },
    },
    data: () => ({
        activePicker: 'YEAR',
        time_or_date: "date",
        internal_value: null,
        internal_date: null,
        menu: false,
        internal_max: null,
        internal_min: null,
    }),
    watch: {
        menu(val) {
            val && setTimeout(() => (this.activePicker = 'DAY'))
        },
    },
    computed: {
        ...mapGetters({
            current_item: "dialog/current_item"
        }),
        format_date_time() {
            if (this.internal_value) {
                if (this.time) {
                    return format(this.internal_value)
                } else {
                    return format_date(this.internal_value)
                }
            }
            return null
        },
    },
    methods: {
        save_date(date) {
            let [year, month, day] = date.split("-")
            this.internal_date = date
            this.internal_value = new DateTime({
                year: Number(year),
                month: Number(month),
                day: Number(day)
            })
            if (this.time) {
                this.time_or_date = "time"
            } else {
                this.$refs.menu.save(this.internal_value)
            }
            this.$emit("input", this.internal_value.internal_value)
        },
        save_hour(hour) {
            this.internal_value.internal_value.hour = Number(hour)
            this.$emit("input", this.internal_value.internal_value)
        },
        save_minute(minute) {
            this.internal_value.internal_value.minute = Number(minute)
            this.$refs.menu.save(this.internal_value)
            this.$emit("input", this.internal_value.internal_value)

        },
    },
    created() {
        this.internal_value = this.value
        if (this.max === "today") {
            this.internal_max = (
                new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)
        }
        if (this.min === "today") {
            this.internal_min = (
                new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10)
        }
    }
}
</script>

<style scoped>
.no-scroll {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>