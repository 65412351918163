<template>
    <div>
        <IndeTableView
            :actions="action"
            :resource="resource"
            :init_sort_desc="[true]"
            :init_sort_by="['_created']"
        >
            <template #item.actions="{ item }">
                <copy-to-clipboard
                    v-if="item?.params?.stoken"
                    :value="item?.params?.ssh_cmd_fmt.replace('%s', item?.params?.stoken)">
                    <v-icon>
                        mdi-content-copy
                    </v-icon>
                </copy-to-clipboard>
                <p v-else> nan </p>
            </template>
        </IndeTableView>
    </div>
</template>

<script>
import IndeTableView from "@/shared/components/view/inde_table_view.vue";
import CopyToClipboard from "@/shared/components/other/CopyToClipboard.vue";

export default {
    name: "TmateEvents",
    components: {CopyToClipboard, IndeTableView},
    data() {
        return {
            resource: "tmate",
            action: true
        }
    }
}
</script>

<style scoped>

</style>