<template>
    <div>
        <v-dialog v-model="dialog" max-width="800px">
            <v-card>
                <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="api_delete_item">OK</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: 'DeleteDialog',
    emits: ["refresh"],
    mixins: [api_mixin, layout_mixin],
    methods: {
        ...mapActions({
            change_delete_item: "dialog/change_delete_item",
            change_refresh_resource: "dialog/change_refresh_resource",
        }),
        async api_delete_item() {
            let res = await this.api_delete({
                resource: this.delete_item.resource,
                item: this.delete_item.item
            })
            this.$emit("refresh")
            let name = this.delete_item.item.name ? this.delete_item.item.name : "name"
            let success_message = `Deleted ${name} from resource ${this.delete_item.resource}`
            this.toast_results(res, success_message)
            await this.change_refresh_resource({resource: this.delete_item.resource, value: true})
            await this.change_delete_item({resource: null, resource_id: null})
        }
    },
    computed: {
        ...mapGetters({
            delete_item: "dialog/current_delete_item",
        }),
        dialog: {
            set(val) {
                if (!val) {
                    this.change_delete_item({resource: null, item: null})
                }
            },
            get() {
                return this.delete_item.resource
            }
        }
    },
    created() {
    }

}
</script>
