<template>
    <v-bottom-navigation
        fixed
        class="overflow-y-auto"
        background-color="rgba(46,46,46,0.70)"
    >
        <v-btn text :to="{name: 'Home'}">
            Home
        </v-btn>
        <v-btn v-for="link in calc_links" v-bind:key="link.name" text :to="link.url">
            {{ link.name }}
        </v-btn>
    </v-bottom-navigation>
</template>

<script>


import {layout_mixin} from "@/shared/mixins/layout";
import {mapGetters} from "vuex";

export default {
    name: "button_bar",
    mixins: [layout_mixin],
    computed: {
        ...mapGetters({current_user: "auth/current_user"}),
        calc_links() {
            if (this.current_user === null) {
                return this.links.common.filter(x => !x.hide_on_mobile)
            }
            if (this.current_user?.is_admin) {
                return this.links.common.filter(x => !x.hide_on_mobile).concat(this.links.admin.filter(x => !x.hide_on_mobile))
            } else {
                return this.links.common.filter(x => !x.hide_on_mobile)
            }
        }
    }
}
</script>

<style scoped>

</style>