import {api_mixin} from "@/shared/mixins/api_mixin";

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export let stepper_store = {
    namespaced: true,
    state: {
        page: 1,
        inverter: {
            address: "", country_code: "", customer_id: "",
            integration: "", latitude: null, longitude: null, number_of_solar_panels: 0,
            total_watt: 0, watt_per_panel: 0, start_date: null
        },
        sma_credentials: {
            username: "",
            password: "",
            plant_names: []
        },
        customer: {customer_id: "", post_address: "", email: ""},
        create_new: false,
        existing_customers: [],
        open: false
    },
    mutations: {
        reset_all(state) {
            state.open = false
            state.page = 1
            state.inverter = {
                address: "", country_code: "", customer_id: "",
                integration: "", latitude: null, longitude: null, number_of_solar_panels: 0,
                total_watt: 0, watt_per_panel: 0
            }
            state.sma_credentials = {
                username: "",
                password: "",
                plant_names: []

            }
            state.customer = {customer_id: "", post_address: "", email: ""}

        },
        change_dialog(state, val) {
            state.open = val
        },
        next_page(state) {
            state.page += 1
        },
        prev_page(state) {
            state.page -= 1
        },
        mutate_customer(state, customer) {
            state.customer = customer
            state.inverter.customer_id = customer.customer_id
        },
        mutate_inverter(state, inverter) {
            console.log("inverter", inverter)
            state.inverter = inverter
        },
        mutate_existing_customers(state, existing_customers) {
            state.existing_customers = existing_customers
        },
        mutate_sma_credentials(state, sma_credentials) {
            state.sma_credentials = sma_credentials
        },
        change_create_new(state, new_val) {
            state.create_new = new_val
        }
    },
    getters: {
        get_page(state) {
            return state.page
        },
        inverter(state) {
            return state.inverter
        },
        customer(state) {
            return state.customer
        },
        sma_credentials(state) {
            return state.sma_credentials
        },



        existing_customers(state) {
            return state.existing_customers
        }
    },
    actions: {
        change_open({commit}, val) {
            commit("change_dialog", val)
        },
        change_create_new({commit}, new_val) {
            commit("change_create_new", new_val)
        },
        async fetch_existing_customers({commit}) {
            commit("mutate_existing_customers", await customer_mixin.methods.get_costumers())
        },
        set_customer({state, commit}, customer) {
            if (state.create_new) {
                if (state.existing_customers.indexOf(customer) === -1) {
                    throw "Existing customer doesn't exists"
                } else {
                    commit("mutate_customer", customer)
                }
            } else {
                commit("mutate_customer", customer)
            }
        },
        reset_customer({commit}) {
            commit("mutate_customer", {"customer_id": "", "post_address": "", "email": ""})

        },
        set_inverter({commit}, inverter) {
            commit("mutate_inverter", inverter)
        },
        set_sma_credentials({commit}, sma_credentials) {
            commit("mutate_sma_credentials", sma_credentials)
        },
        reset_inverter({commit}) {
            commit("mutate_inverter", {
                address: "", country_code: "", customer_id: "",
                integration: "", latitude: "", longitude: "", number_of_solar_panels: "",
                total_watt: "", watt_per_panel: ""
            })
        },
        async submit({state, commit}) {
            let res = {customer: {msg: "", ok: false}, inverter: {msg: "", ok: false}}
            if (state.create_new) {
                res.customer = await api_mixin.methods.api_post({resource: "customer", data: state.customer})
            } else {
                res.customer.msg = null
                res.customer.ok = true
            }
            if (res.customer.ok) {
                if (state.inverter.integration === "sma") {
                    state.inverter.sma_credentials = {
                        username: state.sma_credentials.username,
                        password: state.sma_credentials.password
                    }
                }
                let inverter_res = await api_mixin.methods.api_post({resource: "inverters", data: state.inverter})
                console.log("inverter_res", inverter_res)
                res.inverter = inverter_res
            }
            if (res.customer.ok && res.inverter.ok) {
                commit("reset_all")
            }

        }
    }
}




