<template>
    <div>
        <inde-card-view resource="users">
            <template v-slot:item="{ item }">
                <v-card class="ma-3">
                    <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                            <v-card-title
                                class="text-h5"
                                v-text="item.full_name"
                            />
                            <v-card-text>
                                Is admin: {{ item.is_admin }}
                                <br>
                                Email: {{ item.email }}
                                <br>
                            </v-card-text>
                        </div>

                        <v-avatar
                            class="ma-3"
                            size="75"
                            tile
                        >
                            <v-img :src="item.picture"/>
                        </v-avatar>
                    </div>
                    <v-row justify="center" align="center">
                        <v-card-actions>

                            <v-btn v-if="item.is_admin"
                                   depressed
                                   color="primary"
                                   v-on:click="make_admin(item['_id'])"
                            >
                                Make admin
                            </v-btn>
                        </v-card-actions>
                    </v-row>
                </v-card>
            </template>
        </inde-card-view>
    </div>
</template>

<script>
import {layout_mixin} from "@/shared/mixins/layout";
import {mapGetters} from 'vuex'
import IndeCardView from "@/shared/components/view/inde_card_view.vue";
import {api_mixin} from "@/shared/mixins/api_mixin";

export default {
    name: "users",
    components: {IndeCardView},
    mixins: [layout_mixin, api_mixin],
    data: function () {
        return {
            users: [],
        }
    },
    methods: {
        async make_admin(user_id){
            console.log("TODO make admin")
        }
    },
    computed: {
        ...mapGetters({current_user: "auth/current_user"}),
    },
    async created() {
    }
}
</script>

<style scoped>

</style>