<template>
    <IndeView :resource="resource" />
</template>

<script>
import {layout_mixin} from "@/shared/mixins/layout";
import IndeCardView from "@/shared/components/view/inde_card_view.vue";
import {mapGetters} from "vuex";
import {api_mixin} from "@/shared/mixins/api_mixin";
import IndeView from "@/shared/components/view/inde_view.vue";


export default {
    name: "settings",
    components: {
        IndeView,
        IndeCardView,
    },
    mixins: [layout_mixin, api_mixin],
    data: function () {
        return {
            valid: false,
            settings: [],
            loading: false,
            resource: "site_settings"
        }
    },
    computed: {
        ...mapGetters({edited_item: "dialog/current_item"})
    },
    methods: {
        async save() {
        },
    },

}
</script>

<style scoped>

</style>