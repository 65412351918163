export let dashboard_mixin = {
    data: function () {
        return {}
    },
    methods: {
        create_data(data, x_label, y_label, type) {
            return [{
                x: data.map((row) => row[x_label]),
                y: data.map((row) => row[y_label]),
                type: type
            }]
        },
        create_trace({x, y, type}) {
            return {
                x, y, type
            }
        }
    }
}
