<template>
    <section id="hero">
        <v-parallax dark src="@/assets/img/bggrenada.jpg">
            <v-row align="center" justify="center">
                <v-col cols="10">
                    <v-row align="center" justify="center">
                        <v-col cols="12">
                            <h1 class="display-2 font-weight-bold mb-4 dark-background">IndePower</h1>
                            <h1 class="font-weight-light dark-background">
                                <br/>
                                Solar energy made easy!<br/>
                                Coming soon!
                            </h1>
                            <v-btn

                                rounded
                                outlined
                                large
                                dark
                                @click="$vuetify.goTo('#features')"
                                class="mt-5 text--black"

                            >
                                More info below!
                                <v-icon class="ml-2">mdi-arrow-down</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="6" xl="4" class="hidden-sm-and-down"></v-col>
                    </v-row>
                </v-col>
            </v-row>
            <div class="svg-border-waves text-white">
                <v-img src="@/assets/img/borderWaves.svg"/>
            </div>
        </v-parallax>
        <v-container fluid id="features" class="mt-2">
            <v-row align="center" justify="center">
                <v-col cols="10">
                    <v-row align="center" justify="space-around">
                        <v-col cols="12" class="text-center">
                            <h1 class="font-weight-light display-2">Solar energy</h1>
                            <h1 class="font-weight-light">
                                Our mission is to accelerate the transition of renewable energy in Grenada
                            </h1>
                        </v-col>
                    </v-row>
                    <v-row>

                        <v-col
                            cols="12"
                            sm="12"
                            md="4"
                            class="text-center"
                            v-for="(feature, i) in features"
                            :key="i"
                        >
                            <v-hover v-slot:default="{ hover }">
                                <v-card
                                    class="card"
                                    shaped
                                    min-height="200"
                                    :elevation="hover ? 10 : 4"
                                    :class="{ up: hover }"
                                >
                                    <v-img
                                        :src="feature.img"
                                        max-width="100px"
                                        class="d-block ml-auto mr-auto"
                                        :class="{ 'zoom-efect': hover }"
                                    ></v-img>
                                    <h1 class="font-weight-regular">{{ feature.title }}</h1>
                                    <h4 class="font-weight-regular subtitle-1">
                                        {{ feature.text }}
                                    </h4>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <div class="svg-border-waves">
            <img src="~@/assets/img/wave2.svg" alt="wave"/>
        </div>
    </section>
</template>

<script>

import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: "Home",
    mixins: [layout_mixin],
    data() {
        return {
            dialog: false,
            features: [
                {
                    img: require("@/assets/img/icon2.png"),
                    title: "Low operating costs and easy to install",
                    text: "The photovoltaic process that transforms sunlight into electricity does’t require any fuel and has no variable costs.",
                },
                {
                    img: require("@/assets/img/icon1.png"),
                    title: "Sustainable",
                    text: "Save the environment and become less dependent on the oil price",
                },
                {
                    img: require("@/assets/img/icon3.png"),
                    title: "See results!",
                    text: "Monitor how your solar panels perform",
                },
            ],
        };
    },
    async created() {
        // await this.introspect()
    }
}
</script>

<style lang="scss">

.circle {
    stroke: white;
    stroke-dasharray: 650;
    stroke-dashoffset: 650;
    -webkit-transition: all 0.5s ease-in-out;
    opacity: 0.3;
}

.playBut {
    /*  border: 1px solid red;*/
    display: inline-block;
    -webkit-transition: all 0.5s ease;

    .triangle {
        -webkit-transition: all 0.7s ease-in-out;
        stroke-dasharray: 240;
        stroke-dashoffset: 480;
        stroke: white;
        transform: translateY(0);
    }

    &:hover {
        .triangle {
            stroke-dashoffset: 0;
            opacity: 1;
            stroke: white;
            animation: nudge 0.7s ease-in-out;

            @keyframes nudge {
                0% {
                    transform: translateX(0);
                }
                30% {
                    transform: translateX(-5px);
                }
                50% {
                    transform: translateX(5px);
                }
                70% {
                    transform: translateX(-2px);
                }
                100% {
                    transform: translateX(0);
                }
            }
        }

        .circle {
            stroke-dashoffset: 0;
            opacity: 1;
        }
    }
}
</style>

<style>
.btn-play {
    transition: 0.2s;
}

.svg-border-waves .v-image {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3rem;
    width: 100%;
    overflow: hidden;
}

#hero {
    z-index: 0;
}

.svg-border-waves img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: -2px;
    z-index: -1;
}

.card {
    min-height: 300px;
    padding: 10px;
    transition: 0.5s ease-out;
}

.card .v-image {
    margin-bottom: 15px;
    transition: 0.75s;
}

.card h1 {
    margin-bottom: 10px;
}

.zoom-efect {
    transform: scale(1.1);
}

.up {
    transform: translateY(-20px);
    transition: 0.5s ease-out;
}

.dark-background {
    text-shadow: 0 0 1em #000000;
}

</style>

<style>
section {
    position: relative;
}
</style>
