<template>
    <div>
        <loading v-if="loading_vuex"></loading>
        <v-tabs v-model="tab" center-active>
            <v-tab to="/admin/inverters" key="inverter">Inverter</v-tab>
            <v-tab to="/admin/customers" key="customers">Customers</v-tab>
            <v-tab to="/admin/users" key="users">Users</v-tab>
            <v-tab to="/admin/api_keys" key="api_keys">Api Keys</v-tab>
            <v-tab to="/admin/batch_jobs_schedule" key="batch_jobs_schedule">Batch Jobs Schedule</v-tab>
            <v-tab to="/admin/site_settings" key="site_settings">Site Settings</v-tab>
            <v-tab to="/admin/tmate_events" key="tmate_events">Tmate events</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item id="/admin/inverters" key="inverter">
                <inverter v-if="tab === '/admin/inverters'"/>
            </v-tab-item>
            <v-tab-item id="/admin/customers" key="customers">
                <customers v-if="tab === '/admin/customers'"/>
            </v-tab-item>
            <v-tab-item id="/admin/users" key="users">
                <users v-if="tab === '/admin/users'"/>
            </v-tab-item>
            <v-tab-item id="/admin/api_keys" key="api_keys">
                <api-keys v-if="tab === '/admin/api_keys'"/>
            </v-tab-item>
            <v-tab-item id="/admin/site_settings" key="site_settings">
                <settings v-if="tab === '/admin/site_settings'"/>
            </v-tab-item>
            <v-tab-item id="/admin/batch_jobs_schedule" key="batch_jobs_schedule">
                <batch-schedules v-if="tab === '/admin/batch_jobs_schedule'"/>
            </v-tab-item>
            <v-tab-item id="/admin/tmate_events" key="tmate_events">
                <TmateEvents/>
            </v-tab-item>
        </v-tabs-items>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Loading from "@/shared/components/loading";
import Inverter from "@/views/components/admin/inverter";
import Customers from "@/views/components/admin/customers";
import Users from "@/views/components/admin/users";
import ApiKeys from "@/views/components/admin/api_keys";
import Settings from "@/views/components/admin/settings";
import BatchSchedules from "@/views/components/admin/batch_schedules";
import IndeDataTable from "@/shared/components/view/inde_table_view.vue";
import TmateEvents from "@/views/components/admin/tmate_events.vue";

export default {
    name: "Admin",
    components: {TmateEvents, IndeDataTable, BatchSchedules, Settings, ApiKeys, Users, Customers, Inverter, Loading,},
    props: {
        tab_url: String
    },
    data: function () {
        return {
            tab: null
        }
    },
    computed: {
        ...mapGetters({loading_vuex: "loading"})
    },
    created() {
        this.tab = this.tab_url
    }
}
</script>

<style scoped>

</style>