<template>
    <div>
        <v-btn
            v-if="allowed"
            color="primary"
            class="mb-2"
            @click="create_item"
        >
            {{ translate("common.new_item") }}
        </v-btn>
    </div>
</template>
<script>
import {layout_mixin} from "@/shared/mixins/layout";
import {mapActions, mapGetters} from "vuex";
import {api_mixin} from "@/shared/mixins/api_mixin";

export default {
    name: "CreateNew",
    emits: ["refresh"],
    mixins: [layout_mixin, api_mixin],
    props: {
        resource: String,
        resource_id: String,
        hide_create_new: {
            type: Boolean,
            default: false
        },
        default_data: {
            type: Object,
            default() {
                return {}
            }
        },
        locked_data: {
            type: Object,
            default() {
                return {}
            }
        },
        dialog_settings: {
            // dialog_settings is not domain_settings
            type: Object,
            default() {
                return {}
            }
        }
    },
    watch: {
        dialog_vuex(val, old_val) {
            if (!val && old_val) {
                this.close_item()
            }
        }
    },
    data() {
        return {
            internal_value: undefined,
            loaded: false,
            allowed: false
        }
    },
    computed: {
        ...mapGetters({
            domain_settings: "dialog/domain_settings",
            edited_item_vuex: "dialog/current_item",
        }),
    },
    methods: {
        ...mapActions({
            open_item: "dialog/open_item",
            set_resource: "dialog/set_resource",
            set_loading: "dialog/set_loading",
            close_item: "dialog/close_item"
        }),
        create_item() {
            let default_data_copy = {...this.default_data}
            let locked_data_copy = {...this.locked_data}
            this.open_item({
                    locked_data: locked_data_copy,
                    resource: this.resource,
                    resource_id: null,
                    item: default_data_copy,
                    mode: "create",
                    dialog_settings: this.dialog_settings,
                    tab: 0
                }
            )
        },
        create_allowed() {
            let ret = {
                view: true,
                create: false,
                edit: false
            }
            if (this.domain_settings?.[this.resource]?.resource_methods == null) {
                console.log(`Missing domain_settings for resource ${this.resource}`)
                return ret
            }

            if (this.hide_create_new) {
                return ret
            }
            if (Array.isArray(this.domain_settings[this.resource].resource_methods)) {
                ret["create"] = this.domain_settings[this.resource].resource_methods.includes('POST')
            } else {
                ret["create"] = false
            }
            if (Array.isArray(this.domain_settings[this.resource].item_methods)) {
                ret["edit"] = this.domain_settings[this.resource].item_methods.includes("PUT")
            } else {
                ret["edit"] = false
            }
            return ret
        },
    },
    async created() {
        await this.set_resource(this.resource)
        let allowed_dict = this.create_allowed()
        this.allowed = allowed_dict.create
        await this.set_loading(false)
    }
}
</script>