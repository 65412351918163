import Vuex from 'vuex'
import Vue from 'vue'
import {auth_store, create_persistent_storage} from "@/shared/store/auth";
import {stepper_store} from "@/store/stepper_store";
import {dialog_store} from "@/shared/store/dialog_store";
import {variables_store} from "./variables";


Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        loading: false
    },
    mutations: {
        set_loading(state, value) {
            state.loading = value
        }
    },
    getters: {
        loading(state) {
            return state.loading
        }
    },
    modules: {
        auth: auth_store,
        stepper: stepper_store,
        dialog: dialog_store,
        variables: variables_store,

    },
    plugins: [create_persistent_storage(["auth"]).plugin],

})
