<template>
    <div id="app">
        <v-app v-if="loaded">
            <TopBar v-if="!small_screen" hide_github_login></TopBar>
            <v-main>
                <v-container fluid>
                    <v-row>
                        <v-col>
                            <router-view/>
                        </v-col>
                    </v-row>
                </v-container>
                <Button_bar v-if="small_screen"></Button_bar>
            </v-main>
        </v-app>
        <loading v-else/>
        <IndeDialog/>
        <DeleteDialog/>
    </div>
</template>

<script>
import TopBar from "@/shared/components/layout/TopBar";
import Loading from "@/shared/components/loading";
import {token_mixin} from "@/shared/mixins/token";
import {mapActions, mapGetters} from "vuex";
import Button_bar from "@/shared/components/layout/BottonBar";
import {layout_mixin} from "@/shared/mixins/layout";
import IndeDialog from "@/shared/components/dialogs/inde_dialog.vue";
import DeleteDialog from "@/shared/components/dialogs/delete_dialog.vue";

export default {
    name: 'App',
    mixins: [token_mixin, layout_mixin],
    components: {
        DeleteDialog,
        IndeDialog,
        Button_bar,
        Loading,
        TopBar
    },
    data() {
        return {
            loaded: false,
        }
    },
    computed: {
        ...mapGetters({current_user: "auth/current_user"})
    },
    methods: {
        ...mapActions({
            logout: "auth/logout",
            load_user: "auth/load_user",
            refresh_token: "auth/refresh_token",
            load_user_settings: "auth/load_user_settings"
        })
    },
    async created() {
        if (this.$router.currentRoute.name === "Activate") {
            this.loaded = true
            return
        }
        let load_user = true
        if (!this.check_token_valid()) {
            // token is not valid
            if (this.check_refresh_token_valid()) {
                load_user = await this.refresh_token()
            } else {
                // neither token or refresh token is valid
                load_user = false
            }
        } else {
            load_user = true
        }
        if (load_user) {
            await this.load_user()
            await this.load_user_settings({i18n: this.$i18n, vuetify: this.$vuetify})
        }

        this.loaded = true
    }
}
</script>

<style lang="scss">
</style>
