import Vue from "vue";

export let dict_mixin = {
    methods: {

        deep_copy(item) {
            if (!(this.is_object(item))) {
                return item
            }
            return JSON.parse(JSON.stringify(item))
        },
        deep_delete(item, dotted_path) {
            if (dotted_path.indexOf(".") > -1) {
                let keys = dotted_path.split(".")
                let first_key = this.is_object(item) ? keys[0] : parseInt(keys[0])
                if (first_key in item) {
                    this.deep_delete(item[first_key], keys.slice(1).join("."))
                } else {
                    console.warn(`Could not delete item ${first_key} from item ${item}`)
                }
            } else {
                if (dotted_path in item) {
                    delete item[dotted_path]
                }
            }
        },
        deep_set(item, dotted_path, value) {
            if ([null, undefined, ""].indexOf(dotted_path) > -1) {
                console.warn("Why is this here")
                item = JSON.parse(JSON.stringify(value))
                return
            }
            if (dotted_path.indexOf(".") > -1) {
                let keys = dotted_path.split(".")
                let first_key = this.is_object(item) ? keys[0] : parseInt(keys[0])
                if (!(first_key in item)) {
                    Vue.set(item, first_key, {})
                }
                this.deep_set(item[first_key], keys.slice(1).join("."), value)
            } else {
                Vue.set(item, dotted_path, value)
            }
        },
        deep_get(item, dotted_path, return_copy = false, default_value = null) {
            let obj = item
            try {
                for (let key of dotted_path.split(".")) {
                    if (obj[key] === undefined) return default_value
                    obj = this.is_object(item) ? obj[key] : obj[parseInt(key)]
                }
            } catch (e) {
                return default_value
            }
            return return_copy ? this.deep_copy(obj) : obj
        },
        is_object(val) {
            return (
                typeof val === 'object' &&
                !Array.isArray(val) &&
                val !== null
            )
        }
    }
}
