<template>
    <div>
    </div>
</template>

<script>
import router from "@/router";
import {mapActions, mapGetters} from "vuex";
import {layout_mixin} from "@/shared/mixins/layout";
import {dict_mixin} from "@/shared/mixins/dict_mixin";

export default {
    name: "oauth",
    mixins: [dict_mixin],
    props: {
        token: String,
        refresh_token: String,
    },
    methods: {
        ...mapActions({
            load_user: "auth/load_user",
            load_user_settings: "auth/load_user_settings"
        }),
        redirect() {
            console.log(`Redirecting to`, this.next)
        },
        async register_token() {
            this.$store.commit('auth/insert_token', {token: this.token, refresh_token: this.refresh_token})
        },
        check_error() {
            if (this.is_object(this.$route.query) && Object.keys(this.$route.query).length > 0) {
                console.log("Error from oauth ", this.$route.query)
            }
        }
    },
    computed: {
        ...mapGetters({"next": "auth/next"})
    },
    async created() {
        this.check_error()
        await this.register_token()
        let successful = await this.load_user()
        console.log("Success oath", successful)
        if (successful) {
            await this.load_user_settings({vuetify: this.$vuetify, i18n: this.$i18n})
            console.log("loaded user settings")
            console.log("Next is ", this.next)
            if (this.next.name === null) {
                await router.push({name: "Home"})
            }
            await router.push(this.next)
        } else {
            await router.push({name: "Home"})
        }
    }
}
</script>

<style scoped>

</style>