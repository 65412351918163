import {dict_mixin} from "@/shared/mixins/dict_mixin";
import {api_mixin} from "@/shared/mixins/api_mixin";

export const dialog_store = {
    namespaced: true,
    state() {
        return {
            loading: false,
            current_item: {
                resource_id: null,
                resource: null,
                mode: null,
                location: null,
                tab: null,
                locked_data: {},
                dialog_settings: {},
                open_on_close: {},
            },
            delete_item: {
                resource: null,
                item: null
            },
            domain_settings: {},
            label_cache: {},
            items: {},
            history: [],
            refresh_resource: {},

        }
    },
    mutations: {
        set_property(state, {dotted_path, value, copy = false}) {
            if (copy) {
                value = {...value}
            }
            dict_mixin.methods.deep_set(state, dotted_path, value)
        },
        delete_property(state, {dotted_path}) {
            dict_mixin.methods.deep_delete(state, dotted_path)
        },
        add_history({history}, {resource, resource_id}) {
            let indexes_to_remove = []
            for (let index in history) {
                if ((resource_id === history[index].resource_id) &&
                    (resource === history[index].resource)) {
                    indexes_to_remove.push(index)
                }
            }
            for (let index of indexes_to_remove) {
                history.splice(index, 1)
            }
            history.unshift({resource: resource, resource_id: resource_id})
        },
    },
    actions: {
        set_loading({commit}, value) {
            commit("set_property", {dotted_path: "loading", value: value})
        },
        change_refresh_resource({commit, state}, {resource, value}) {
            if (value === undefined) {
                throw "This function is not updated"
            }
            if (value) {
                if (!state.refresh_resource[resource]) {
                    commit("set_property", {dotted_path: `refresh_resource.${resource}`, value: true})
                } else {
                    console.log(state.refresh_resource)
                    console.log(`Check 1 new val ${value} current_val ${state?.["refresh_resource"]?.[resource]} resource ${resource}`)
                    console.log(`
                    Check 1. New value is true ${value} same as current val
                     ${state?.["refresh_resource"]?.[resource]} for resource ${resource}`)
                    commit("set_property", {dotted_path: `refresh_resource.${resource}`, value: false})
                }
            } else {
                if (state.refresh_resource[resource] === true) {
                    commit("set_property", {dotted_path: `refresh_resource.${resource}`, value: false})
                } else {
                    console.log(state.refresh_resource)
                    console.log(`Check 2 new val ${value} current_val ${state.refresh_resource} resource ${resource}`)
                }
            }
        },
        async open_item({state, commit, dispatch,}, {
            resource,
            resource_id = null,
            item = null,
            locked_data = {},
            mode = "view",
            hide_edit = false,
            hide_create = false,
            open_on_close = null,
            dialog_settings = {},
            tab = 0,
            location = "default"
        }) {
            await dispatch("set_resource", resource)
            let valid_mode = ["view", "edit", "create"].includes(mode)
            if (!valid_mode) {
                throw `Invalid mode here ${mode} ${valid_mode} ${resource} `
            }
            let valid_location = ["right", "left", "fullscreen", "default"].includes(location)
            if (!valid_location) {
                throw `Invalid location here ${location} ${valid_location} `
            }

            if (mode === "create") {
                if (resource_id === "create") {
                    let saved_item = dict_mixin.methods.deep_get(state, `items.${resource}.${resource_id}`)
                    if (saved_item) {
                        console.log("saved_item", saved_item)
                    }
                } else if (resource_id !== null) {
                    throw "Resource id but we are creating"
                }
                resource_id = "create"

            } else if (['view', 'edit'].includes(mode)) {
                if (item === null) {
                    if (resource !== null && resource_id !== null) {
                        item = await api_mixin.methods.api_get_items({
                            resource,
                            lookup: {
                                "_id": resource_id
                            },
                            first: true
                        })
                    }
                }
            }
            if (state.current_item.resource === resource && state.current_item.resource_id === resource_id) {
                console.log("The item is the same as the current one...")
                return
            }
            commit("add_history", {resource, resource_id})
            commit("set_property", {dotted_path: "current_item.locked_data", value: locked_data})
            commit("set_property", {dotted_path: "current_item.mode", value: mode})
            commit("set_property", {dotted_path: "current_item.resource_id", value: resource_id})
            commit("set_property", {dotted_path: "current_item.resource", value: resource})
            commit("set_property", {dotted_path: "current_item.dialog_settings", value: dialog_settings})
            commit("set_property", {dotted_path: "current_item.open_on_close", value: open_on_close})
            commit("set_property", {dotted_path: "current_item.tab", value: tab})
            commit("set_property", {dotted_path: "current_item.location", value: location})
            commit("set_property",
                {
                    dotted_path: `items.${resource}.${resource_id}`,
                    value: {
                        resource,
                        resource_id,
                        item,
                        mode,
                        hide_edit,
                        hide_create,
                        open_on_close,
                        locked_data,
                        dialog_settings,
                        tab,
                        location
                    }
                })
        },
        close_item({state, commit, dispatch}) {
            let current_item = state.current_item
            let {resource, resource_id, open_on_close} = current_item
            let stored_item = dict_mixin.methods.deep_get(state.items, `${resource}.${resource_id}`)
            if (stored_item === null) {
                console.warn("state.items ", state.items)
                throw `Can't find item to close ${resource} ${resource_id}`
            }
            commit("delete_property", {dotted_path: `items.${resource}.${resource_id}`})
            let indexes_to_remove = []
            for (let index in state.history) {
                if ((resource_id === state.history[index].resource_id) && (resource === state.history[index].resource)) {
                    indexes_to_remove.push(index)
                } else if (index - indexes_to_remove + 1 > 5) {
                    console.warn(`Removing index ${index} because ${index} - ${indexes_to_remove} + ${1}>6`)
                    indexes_to_remove.push(index)
                }
            }
            for (let index of indexes_to_remove) {
                state.history.splice(index, 1)
            }
            commit("set_property", {dotted_path: "current_item.locked_data", value: null})
            commit("set_property", {dotted_path: "current_item.mode", value: null})
            commit("set_property", {dotted_path: "current_item.resource_id", value: null})
            commit("set_property", {dotted_path: "current_item.resource", value: null})
            commit("set_property", {dotted_path: "current_item.dialog_settings", value: null})
            commit("set_property", {dotted_path: "current_item.open_on_close", value: null})
            commit("set_property", {dotted_path: "current_item.tab", value: null})
            commit("set_property", {dotted_path: "current_item.location", value: "default"})
            if (open_on_close) {
                dispatch("open_item", open_on_close)
            }
        },
        change_item({commit, state}, item) {
            commit("set_property", {
                dotted_path: `items.${state.current_item.resource}.${state.current_item.resource_id}.item`,
                value: item
            })
        },
        change_mode({commit, state}, mode) {
            let tmp = ["view", "edit", "create"].includes(mode)
            if (!tmp) {
                throw `Invalid mode (change_mode) ${mode} ${tmp}`
            }
            commit("set_property", {
                dotted_path: `items.${state.current_item.resource}.${state.current_item.resource_id}.mode`,
                value: mode
            })
            commit("set_property", {
                dotted_path: `current_item.mode`,
                value: mode
            })
        },
        change_tab({commit, state}, tab) {
            commit("set_property", {
                dotted_path: `items.${state.current_item.resource}.${state.current_item.resource_id}.tab`,
                value: tab
            })
            commit("set_property", {
                dotted_path: `current_item.tab`,
                value: tab
            })
        },
        change_dialog_location({commit, state}, location) {
            let valid_location = ["right", "left", "fullscreen", "default"].includes(location)
            if (!valid_location) {
                throw `Invalid location here ${location} ${valid_location}`
            }
            commit("set_property", {
                dotted_path: `items.${state.current_item.resource}.${state.current_item.resource_id}.location`,
                value: location
            })
            commit("set_property", {
                dotted_path: `current_item.location`,
                value: location
            })
        },
        async set_resource({commit, state}, resource) {
            if (!resource) {
                throw "No resource in get schema " + resource
            }
            if (!(resource in state.domain_settings)) {
                let schema = await api_mixin.methods.get_schema(resource)
                commit("set_property", {dotted_path: `domain_settings.${resource}`, key: resource, value: schema})
            }
        }
        ,
        async reload_item({commit, state, getters}, {resource, resource_id}) {
            if (dict_mixin.methods.deep_get(state, `items.${resource}.${resource_id}`) === null) {
                throw `Item doesn't exist in dialog store ${resource}.${resource_id}`
            }
            let res = await api_mixin.methods.api_get_items(
                {
                    resource,
                    lookup: {"_id": resource_id},
                    first: true,
                    disable_cache: true
                }
            )
            if (!res) {
                throw `Could not find item with resource ${resource} id ${resource_id}`
            }
            if (state.current_item.resource === resource && state.current_item.resource_id === resource_id) {
                for (let key of Object.keys(res)) {
                    commit("set_property", {
                        dotted_path: `items.${resource}.${resource_id}.item.${key}`,
                        value: res[key]
                    })
                }
            } else {
                commit(
                    "set_property", {
                        dotted_path: `items.${resource}.${resource_id}.item`,
                        value: res
                    }
                )
            }

        },
        change_delete_item({commit, state}, {resource, item}) {
            commit("set_property", {
                dotted_path: "delete_item",
                value: {resource, item}
            })
        },
        set_label_cache({commit, state}, {resource, item_id, field, value}) {
            commit(
                "set_property", {
                    dotted_path: `label_cache.${resource}.${item_id}.${field}`,
                    value: value
                }
            )
        },
        get_label_cache({state}, {resource, item_id, field}) {
            return dict_mixin.methods.deep_get(
                state, `label_cache.${resource}.${item_id}.${field}`
            )
        }
    },
    getters: {
        domain_settings(state) {
            return state.domain_settings;
        },
        loading(state) {
            return state.loading;
        },
        current_id(state) {
            return state.current_item.resource_id;
        },
        current_resource(state) {
            return state.current_item.resource;
        },
        current_locked_data(state) {
            return state.current_item.locked_data;
        },
        current_dialog_settings(state) {
            return state.current_item.dialog_settings;
        },
        current_item(state) {
            if (state.current_item.resource === null) {
                return null
            }
            if (state.current_item.resource_id === null) {
                return null
            }
            let current_item = dict_mixin.methods.deep_get(
                state,
                `items.${state.current_item.resource}.${state.current_item.resource_id}`
            )
            if (!current_item) {
                throw `Missing item ${state.current_item.resource} ${state.current_item.resource_id}`
            }

            return state.items[state.current_item.resource][state.current_item.resource_id]
        },
        current_mode(state) {
            return state.current_item.mode
        },
        current_location(state) {
            return state.current_item.location
        },
        current_tab(state) {
            return state.current_item.tab
        },
        current_delete_item(state) {
            // active: false,
            // resource: null,
            // item: null
            return state.delete_item
        },
        refresh_resource(state) {
            return state.refresh_resource
        },
    }
}