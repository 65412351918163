<template>
    <div>
        <v-toolbar flat>
            <v-toolbar-title class="hidden-sm-and-down">{{ translate(resource) }}</v-toolbar-title>
            <slot name="search">
            </slot>
            <v-divider
                class="mx-4 hidden-sm-and-down"
                inset
                vertical
            />
            <v-spacer/>
            <slot name="create_new">
                <CreateNew
                    v-if="resource"
                    :resource="resource"
                    :hide_create_new="hide_create_new"
                    :default_data="default_data"
                    :locked_data="locked_data"
                    :dialog_settings="dialog_settings"
                />
            </slot>
        </v-toolbar>
        <IndeTableView
            v-if="internal_view_type=== 'table_view'"
            :resource="resource"
            :lookup="lookup"
            :hide_create_new="hide_create_new"
            :default_data="default_data"
            :init_sort_by="init_sort_by"
            :init_sort_desc="init_sort_desc"
            :actions="actions"
            :actions2="actions2"
            :locked_data="locked_data"
            :expandable="expandable"
            :hide_edit="hide_edit"
            :click_row="click_row"
            hide_header
        >
            <template #item.actions="{item}">
                <slot name="item.actions" :item="item"></slot>
            </template>
            <template #item.actions2="{item}">
                <slot name="item.actions2" :item="item"></slot>
            </template>
        </IndeTableView>
        <IndeCardView
            v-else-if="internal_view_type==='card_view'"
            :resource="resource"
            :lookup="lookup"
            :hide_create_new="hide_create_new"
            :default_data="default_data"
            :init_sort_by="init_sort_by"
            :init_sort_desc="init_sort_desc"
            :actions="actions"
            :locked_data="locked_data"
            :expandable="expandable"
            :hide_edit="hide_edit"
            :click_row="click_row"
            hide_header
        >
            <template #create_new>
                <div></div>
            </template>
            <template #item.actions="{item}">
                <slot name="item.actions" :item="item"></slot>
            </template>
            <template #item.actions2="{item}">
                <slot name="item.actions2" :item="item"></slot>
            </template>
        </IndeCardView>
    </div>
</template>

<script>
import IndeTableView from "@/shared/components/view/inde_table_view.vue";
import IndeCardView from "@/shared/components/view/inde_card_view";
import CreateNew from "@/shared/components/dialogs/create_new_btn.vue";
import {layout_mixin} from "@/shared/mixins/layout";

export default {
    name: "inde-view",
    mixins: [layout_mixin],
    components: {CreateNew, IndeCardView, IndeTableView},
    props: {
        resource: {
            type: String,
            required: true
        },
        lookup: {
            type: Object,
            default: () => {
            }
        },
        default_data: {
            type: Object,
            default: () => {
            }
        },
        locked_data: {
            type: Object,
            default: () => {
            }
        },
        hide_create_new: {
            type: Boolean,
            default: false
        },
        init_sort_by: {
            type: Array,
            default: () => []
        },
        init_sort_desc: {
            type: Array,
            default: () => []
        },
        actions: {
            type: Boolean,
            default: false
        },
        actions2: {
            type: Boolean,
            default: false
        },
        hide_edit: {
            type: Boolean,
            default: false
        },
        expandable: {
            type: Boolean,
            default: false
        },
        view_type: {
            type: String,
            default: "table_view"
        },
        dialog_settings: {
            type: Object,
            default: () => {
            }
        },
        click_row: {
            default: null
        }
    },
    computed: {
        internal_view_type() {
            if (this.view_type === "card_view") {
                return "card_view"
            }
            if (this.$vuetify.breakpoint.smAndDown) {
                return "card_view"
            }
            return "table_view"
        }
    }
}
</script>

<style scoped>

</style>