import store from "@/store";

export function _check_token_valid(token) {
    if (token === null) {
        console.log("Token is null in token_valid")
        return false
    }
    if (typeof token != "object") {
        throw "Invalid token in token_valid function"
    }
    if (!("exp" in token)) {
        console.warn("The token doesn't have the exp key")
        return false
    }
    let now = (new Date().getTime() / 1000)
    let exp = token.exp
    // Tomorrow is later than now
    // Therefore tomorrow is bigger than now
    // If token is valid then exp is later than now
    // And bigger

    // exp > now <-> exp - now > 0 <=> 0 < exp - now
    // 0 < d
    // console.log("diff (valid if bigger then 0)", Math.round((exp - now) * 10) / 10, token.type, new Date())
    // console.log("exp: ", exp)
    // console.log("now: ", now)
    return exp > now;

}


export function check_token_valid() {
    let token = store.getters["auth/decoded_token"]
    if (token === null) {
        console.log("Token is null")
        return null
    }
    return _check_token_valid(token)
}


export function check_refresh_token_valid() {
    let token = store.getters["auth/decoded_refresh_token"]
    if (token === null) {
        console.log("Refresh_token is null")
        return null
    }
    return _check_token_valid(token)
}

export let token_mixin = {
    methods: {
        check_token_valid,
        check_refresh_token_valid
    }
}

