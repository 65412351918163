<template>
    <div>
        <inde-data-table :resource="resource">

        </inde-data-table>
    </div>

</template>

<script>
import IndeDataTable from "@/shared/components/view/inde_table_view.vue";
import {mapActions} from "vuex";
import {api_mixin} from "@/shared/mixins/api_mixin";

export default {
    name: "inverter",
    mixins: [api_mixin],
    components: {IndeDataTable},
    data: function () {
        return {
            resource: "inverters",


        }
    },
    methods: {
        ...mapActions({close_item: "dialog/close_item"}),
    },
    computed: {},
    async created() {

    }
}
</script>

<style scoped>

</style>