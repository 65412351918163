<template>
    <div>
        <v-dialog v-model="dialog" v-if="dialog" :fullscreen="current_location === 'fullscreen'">
            <IndeDialogCard
                :disabled="disabled"
            />
        </v-dialog>
    </div>
</template>
<script>
import {layout_mixin} from "@/shared/mixins/layout";
import {mapActions, mapGetters} from "vuex";
import {api_mixin} from "@/shared/mixins/api_mixin";

export default {
    name: "IndeDialog",
    components: {
        IndeDialogCard: () => import("./inde_dialog_card.vue")
    },
    mixins: [layout_mixin, api_mixin],
    props: {},
    watch: {
        dialog_vuex(val, old_val) {
            if (!val && old_val) {
                this.close_item()
            }
        }
    },
    data() {
        return {
            internal_value: undefined,
            loaded: false,
        }
    },
    computed: {
        ...mapGetters({
            current_resource: "dialog/current_resource",
            current_dialog_settings: "dialog/current_dialog_settings",
            current_item: "dialog/current_item",
            current_mode: "dialog/current_mode",
            current_location: "dialog/current_location",
        }),
        edited_item: {
            get() {
                return this.current_item.item
            },
            set(val) {
                this.change_item(val)
            }
        },
        dialog: {
            get() {
                return !!this.current_resource && !this.current_dialog_settings.disable_dialog
            },
            set(val) {
                if (!val) {
                    this.close_item()
                }
            }
        },
        disabled() {
            if (this.current_mode === "view") {
                return true
            }
            return !["edit", "create"].includes(this.current_mode)
        }
    },
    methods: {
        ...mapActions({
            change_item: "dialog/change_item",
            close_item: "dialog/close_item",
        }),
    },
    async created() {
    }
}
</script>