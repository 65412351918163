<template>
    <div>
        <inde-data-table :resource="resource"/>
    </div>
</template>

<script>
import IndeDataTable from "@/shared/components/view/inde_table_view.vue";


export default {
    name: "batch-schedules",
    mixins: [],
    components: {IndeDataTable},
    data() {
        return {
            resource: "batch_schedules",
        }
    },
    computed: {},
    methods: {},
}
</script>

<style scoped>

</style>