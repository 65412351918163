<template>
    <v-dialog width="80%" v-model="internal_value" v-click-outside="close">
        <v-card>
            <vue-pdf-embed :source="url"/>
        </v-card>
    </v-dialog>
</template>

<script>
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
    name: "pdf-viewer",
    components: {VuePdfEmbed},
    props: {
        url: String,
        value: Boolean
    },
    data() {
        return {
            lazy_val: false
        }
    },
    errorCaptured() {
        return false
    },
    watch: {
        value(val) {
            this.internal_value = val
        },
    },
    methods: {
        close() {
            this.internal_value = false
        }
    },
    computed: {
        internal_value: {
            set(val) {
                this.lazy_val = val
                this.$emit("input", val)
            },
            get() {
                return this.lazy_val
            }
        }
    },
    created() {
        this.lazy_val = this.value
    }

}
</script>

<style scoped>

</style>