<template>
    <v-card>
        <v-card-title>
            Page Not Found
        </v-card-title>
        <v-card-actions>
            <v-btn to="/">
                Go to home
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "page-not-found"
}
</script>

<style scoped>

</style>