import {dict_mixin} from "@/shared/mixins/dict_mixin";
import {format} from "@/shared/helpers/date_helper";
import {api_mixin} from "@/shared/mixins/api_mixin";
import {mapActions} from "vuex";

export const dialog_mixin = {
    data() {
        return {
            tmp_display_store: {}
        }
    },

    computed: {
        schema_object() {
            let tmp = this.domain_setting_computed
            return Object
                .keys(tmp)
                .filter(x => tmp[x].is_object && !tmp[x].is_list && !tmp[x].custom_component)
                .map(x => tmp[x])
        },
        schema_list() {
            let tmp = this.domain_setting_computed
            return Object
                .keys(tmp)
                .filter(x => tmp[x].is_list && tmp[x].readonly === false && !tmp[x].custom_component)
                .map(x => tmp[x])
        },
        schema_fields() {
            let tmp = this.domain_setting_computed
            return Object
                .keys(tmp)
                .filter(x => !(tmp[x].is_object || tmp[x].is_list || tmp[x].name === "_id" || tmp[x].name === "_etag"))
                .map(x => tmp[x])
        },
        schema_custom_components() {
            let tmp = this.domain_setting_computed
            return Object
                .keys(tmp)
                .filter(x => tmp[x].custom_component && (tmp[x].is_object || tmp[x].is_list))
                .map(x => tmp[x])
        },
        domain_setting_computed() {
            if (this.current_mode === undefined) {
                throw "Missing current mode"
            }
            if (this.current_mode === "view") {
                return this.domain_settings.schema
            } else {
                let tmp = this.domain_settings.schema
                return this.remove_read_only_fields(tmp)
            }
        },
        number_of_tabs() {
            let number = 0
            let fields = null
            let schema_type = null
            if (this.schema_fields.length !== 0) {
                schema_type = "root"
                fields = this.schema_fields
                number += 1
            }
            if (this.schema_list.length !== 0) {
                schema_type = "list"
                number += this.schema_list.length
                fields = this.schema_list
            }
            if (this.schema_object.length !== 0) {
                schema_type = "dict"
                number += this.schema_object.length
                fields = this.schema_object
            }
            if (this.schema_custom_components.length !== 0) {
                schema_type = "custom"
                number += this.schema_custom_components.length
                fields = this.schema_custom_components
            }
            return {number, fields, schema_type}
        }
    },
    methods: {
        ...mapActions({
            set_label_cache: "dialog/set_label_cache",
            get_label_cache: "dialog/get_label_cache"
        }),
        remove_read_only_fields(schema) {
            let tmp = {}
            for (let key of Object.keys(schema)) {
                let tmp_schema = schema[key]
                if (tmp_schema.readonly === true) {
                    continue
                }
                if (tmp_schema.is_list) {
                    if (tmp_schema.is_data_type) {
                        tmp[key] = tmp_schema
                    } else {
                        if (!tmp_schema.is_dict) throw "Something is wrong"
                        let copy = {...tmp_schema}
                        copy.schema = this.remove_read_only_fields(copy.schema)
                        tmp[key] = copy
                    }
                } else if (tmp_schema.is_dict) {
                    let copy = {...tmp_schema}
                    copy.schema = this.remove_read_only_fields(tmp_schema.schema)
                    tmp[key] = copy
                } else {
                    tmp[key] = tmp_schema
                }
            }
            return tmp
        },
        get_rules(item) {
            let rules = []
            for (let key in item.rules) {
                if (key === "regex") {
                    let re = new RegExp(item.rules['regex'])
                    rules.push(v => re.test(v) || `Invalid regex`)
                }
            }
            if (item.required) {
                rules.push(v => !!String(v).length || `${item.name} can't be empty`)
            }
            return rules
        },
        vuetify_sort_to_eve(sort_by, sort_desc) {
            let ret = {}
            for (let index in sort_by) {
                ret[sort_by] = !!sort_desc[index];
            }
            return ret
        },
        get_card_view_layout(layout) {
            let max = Math.max.apply(Math, [layout.right.length, layout.left.length])
            let ret = []
            for (let step = 0; step < max; step++) {
                ret.push([layout.left[step], layout.right[step]])
            }
            return ret
        },
        get_schema_item(resource_settings, path) {
            if (path === undefined) {
                throw `path is undefined ${Object.keys(resource_settings)} probably missing value in table config`
            }
            let schema_path = path.split(".").join(".schema.")
            return dict_mixin.methods.deep_get(
                resource_settings.schema, schema_path
            )
        },
        async format_data(item, slot, domain_settings, resource) {
            if (slot.value === "data-table-expand") {
                return {value: null, type: null}
            }
            let value = dict_mixin.methods.deep_get(item, slot.value)
            if (["_created", "_updated", "_id", "_etag"].includes(slot.value)) {
                if (typeof value === 'object' && value !== null) {
                    return {value: format(value), type: null}
                }
            }
            if (!slot.value) {
                console.warn("Slot missing value (maybe throw an error here)")
            }
            let item_setting = this.get_schema_item(
                domain_settings[resource],
                slot.value
            )
            if (item_setting == null) {
                return {value: value, type: null}
            }
            if (item_setting.type === "dict" && item_setting.is_list) {
                return {value: !!value, type: "boolean"}
            } else if (item_setting.type === "objectid") {
                if (value !== null) {
                    let val = await this.get_label_cache({
                        resource: item_setting.data_relation.resource,
                        item_id: value,
                        field: item_setting.data_relation.label
                    })
                    if (val) {
                        value = val
                    } else {
                        let tmp = await api_mixin.methods.api_get_items(
                            {
                                resource: item_setting.data_relation.resource,
                                lookup: {[item_setting.data_relation.field]: value},
                                projection: {[item_setting.data_relation.label]: 1},
                                first: true
                            }
                        )
                        if (tmp === undefined) {
                            throw `Missing data ${item_setting.data_relation.resource} ${item_setting.data_relation.field} ${value}}}`
                        }
                        value = tmp[item_setting.data_relation.label]
                        await this.set_label_cache({
                            resource: item_setting.data_relation.resource,
                            item_id: tmp["_id"],
                            field: item_setting.data_relation.label,
                            value: tmp[item_setting.data_relation.label]
                        })
                    }

                }


            } else if (item_setting.type === "datetime") {
                if (typeof value === 'object' && value !== null) {
                    value = format(value)
                }
            } else if (item_setting.type === "boolean") {
                value = !!value;
            } else if (item_setting.type === "enum") {
                if (value !== null) {
                    value = this.translate(value)
                }
            }

            return {value, type: item_setting.type}
        },
        filter_data(item, setting, field) {
            // only keep the fields we need
            let item_setting = this.get_schema_item(setting, field)
            let value = dict_mixin.methods.deep_get(item, field)
            if (!item_setting) {
                throw `${field}`
            }
            if (item_setting.is_list) {
                if (item_setting.is_data_type) {
                    return item[field]
                } else if (item_setting.is_object) {
                    let ret = []
                    for (let val of item[field]) {
                        let tmp = {}
                        for (let key2 of Object.keys(item_setting.schema)) {
                            if (val[key2]) {
                                tmp[key2] = val[key2]
                                // should we make this recursive
                            }
                        }
                        ret.push(tmp)
                    }
                    return ret
                } else {
                    throw `Wut ${item_setting.name}`
                }
            } else if (item_setting.is_object) {
                let tmp = {}
                for (let key2 of Object.keys(item_setting.schema)) {
                    tmp[key2] = value[key2]
                }
                return tmp
            }
            return value

        }

    }
}