<template>
    <div>
        <input-select
                v-model="app_id"
                resource="apps"
                clearable
        >

        </input-select>
        <v-btn @click="auth_google" :disabled="app_id === null">

        </v-btn>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import InputSelect from "@/shared/components/dialogs/input_select.vue";

export default {
    name: "test",
    components: {InputSelect},
    data() {
        return {
            app_id: null
        }
    },
    methods: {
        auth_google() {
            window.location = `${process.env.VUE_APP_BACKEND_URL}/authorize_email/init/${this.token}`
        }
    },
    computed: {
        ...mapGetters({token: "auth/rawToken"})
    }
}
</script>

<style scoped>

</style>