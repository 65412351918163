<template>
    <div>
        <v-btn @click="copy_value" :color="color">
            <slot name="default"></slot>
        </v-btn>
        <input type="hidden" id="testing-code" :value="value">
    </div>


</template>

<script>
export default {
    name: "CopyToClipboard",
    props: {
        value: {},
        color: {
            type: String
        }
    },
    methods: {
        copy_value() {
            let testingCodeToCopy = document.querySelector('#testing-code')
            testingCodeToCopy.setAttribute('type', 'text')
            testingCodeToCopy.select()

            try {
                document.execCommand('copy');
                this.$toasted.success('Copied text');
            } catch (err) {
                this.$toasted.error('Oops, unable to copy');
            }

            /* unselect the range */
            testingCodeToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
    }
}
</script>

<style scoped>

</style>