<template>
    <div>
        <v-select
            v-model="internal_value"
            :label="label"
            :items="internal_items"
            :item-text="itemText"
            :item-value="itemValue"
            :disabled="disabled"
            :multiple="multiple"
            :rules="rules"
            :clearable="clearable"
        >
            <template #append-item>
                <v-list-item
                    v-if="!disabled && internal_resource !== current_resource && !items.length && !hide_create">
                    <v-btn @click="create_related_item">
                        <v-icon>
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </v-list-item>
            </template>
        </v-select>
    </div>
</template>
<script>
import {api_mixin} from "@/shared/mixins/api_mixin";
import {mapActions, mapGetters} from "vuex";
import {dict_mixin} from "@/shared/mixins/dict_mixin";

export default {
    name: 'InputSelect',
    mixins: [api_mixin, dict_mixin],
    data: function () {
        return {
            internal_items: [],
            internal_value: undefined,
        }
    },
    props: {
        value: {},
        label: {
            type: String,
            default: ""
        },
        items: {
            type: Array,
            default: () => []
        },
        resource: {
            type: String,
            default: null
        },
        lookup: {
            type: Object,
            default() {
                return {}
            }
        },

        itemText: {
            type: String,
            default: 'label'
        },
        itemValue: {
            type: String,
            default: '_id'
        },
        clearable: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        rules: {
            type: Array,
            default: () => []
        },
        hide_create: {
            type: Boolean,
            default: false
        },
        field: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    watch: {
        value(val) {
            this.internal_value = val
            if (this.internal_value !== val) {
                this.load_data()
            }
        },
        internal_value(val) {
            this.$emit("input", val)
        },
        resource(val, old_val) {
            if (old_val === null && val) {
                this.set_resource(val)
                this.load_data()
            }
        }
    },
    computed: {
        ...mapGetters({
            current_locked_data: "dialog/current_locked_data",
            current_resource: "dialog/current_resource",
            current_item: "dialog/current_item",
            current_dialog_settings: "dialog/current_dialog_settings"

        }),
        internal_resource() {
            return this.lookup.resource ? this.lookup.resource : this.resource
        }
    },
    methods: {
        ...mapActions({
            set_resource: "dialog/set_resource",
            open_item: "dialog/open_item",
        }),
        sort_function(a, b) {
            let x, y
            if (a.name === undefined) {
                x = a._created
                y = b._created
            } else {
                x = a.name.toLowerCase();
                y = b.name.toLowerCase();
            }

            //compare the word which is comes first
            if (x > y) {
                return 1;
            }
            if (x < y) {
                return -1;
            }
            return 0;
        },
        async create_related_item() {
            let default_data_copy = {...this.current_locked_data}
            let locked_data_copy = {...this.current_locked_data}

            let dialog_settings = {}
            let current_dialog_settings = this.deep_get(this.current_dialog_settings, `related_resource.${this.internal_resource}`)
            if (current_dialog_settings !== null) {
                dialog_settings = current_dialog_settings
            }
            await this.open_item({
                    resource: this.internal_resource,
                    resource_id: null,
                    item: default_data_copy,
                    mode: "create",
                    locked_data: locked_data_copy,
                    dialog_settings: dialog_settings,
                    open_on_close: this.current_item
                }
            )
        },
        async get_related_options() {
            let lookup = {}
            if (this.lookup.filter) {
                lookup = this.lookup.filter
            }

            if (this.lookup.params) {
                for (let param of Object.keys(this.lookup.params)) {
                    if (this.current_locked_data[this.lookup.params[param]] === undefined) {
                        console.log(this.current_locked_data)
                        throw `Missing |${param}| |${this.lookup.params[param]} | ${this.resource}| from current locked data (input_select) `
                    }
                    lookup[param] = this.current_locked_data[this.lookup.params[param]]
                }
            }
            let aggregation = !!this.lookup.aggregation
            if (aggregation) {
                let tmp = await this.api_get_aggregation_items({
                        resource: this.internal_resource,
                        lookup
                    }
                )
                this.internal_items = tmp.sort(this.sort_function)
            } else {
                let tmp = await this.api_get_items({
                        resource: this.internal_resource,
                        lookup,
                        sort: {},
                        projection: {[this.itemText]: 1},
                    }
                )
                this.internal_items = tmp.sort(this.sort_function)
            }
        },
        async get_single_item() {
            let lookup = {}
            if (this.lookup.filter) {
                lookup = this.lookup.filter
            }
            if (!this.field?.data_relation?.field) {
                throw `Field is missing for resource ${this.internal_resource}`
            }
            lookup[this.field.data_relation.field] = this.internal_value
            let aggregation = !!this.lookup.aggregation
            if (aggregation) {
                let tmp = await this.api_get_aggregation_items({
                        resource: this.internal_resource,
                        lookup
                    }
                )
                this.internal_items = tmp.sort(this.sort_function)
            } else {
                let tmp = await this.api_get_items({
                        resource: this.internal_resource,
                        lookup,
                        sort: {},
                        projection: {[this.itemText]: 1},
                    }
                )
                this.internal_items = tmp.sort(this.sort_function)
            }
        },
        async load_data() {
            if (this.resource) {
                await this.set_resource(this.resource)
                if (this.disabled && this.internal_value) {
                    await this.get_single_item()
                } else {
                    await this.get_related_options()
                }
            } else {
                if (!this.items) {
                    throw "Missing resource or items"
                }
                this.internal_items = this.items
            }
        }
    },
    async created() {
        if (this.multiple && !Array.isArray(this.value)) {
            this.internal_value = []
        } else {
            this.internal_value = this.value
        }
        if (this.resource) {
            if (this.items.length) {
                console.log("items", this.items)
                throw new Error(`Can't have both items and data_relation resource ${this.resource} items: ${this.items}`)
            }
        }
        await this.load_data()
    }
}
</script>