import axios from 'axios';
import store from "@/store"
import {check_token_valid} from "@/shared/mixins/token";

// Note you have to reload the npm service in order to update the envs
export const auth_axios = axios.create({baseURL: process.env.VUE_APP_BACKEND_URL_REST})
export const no_auth_axios = axios.create({baseURL: process.env.VUE_APP_BACKEND_URL_REST})

export async function requestHandler(request) {

    let token_valid = check_token_valid()
    if (token_valid === null) {
        throw "User is not logged in"
    }
    if (!token_valid) {
        await store.dispatch("auth/refresh_token")
    }
    let token_valid2 = check_token_valid()
    let raw_token = store.getters["auth/rawToken"];
    if (!token_valid2) {
        throw "Why"
    }
    if (!raw_token) {
        throw "Invalid token "
    }
    request.headers.token = raw_token;
    return request;
}

auth_axios.interceptors.request.use(requestHandler);
auth_axios.interceptors.response.use(
    response => {
        return response
    }, error => {
        console.log("Error", error)
        return error.response;
    });
no_auth_axios.interceptors.response.use(response => {
    return response;
}, error => {
    console.log("Error", error)
    if (error?.code === "ERR_NETWORK") {
        return {
            data: {
                _status: "offline"
            }
        }
    }
    return error.response;
});