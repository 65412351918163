import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css'
import Toasted from "vue-toasted"; // Ensure you are using css-loader
import "@/shared/css/site.scss"
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Toasted, {
    position: "bottom-right",
    action: {
        text: 'X',
        onClick: (e, toastObject) => {
            toastObject.goAway(0);
        }
    },
    duration: 4000
})

Vue.use(Vuetify);
export let vuetify = new Vuetify({
    icons: {
        iconfont: 'mdi'
    },
})
